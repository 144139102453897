import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SectorService } from '../../../services/sectors/sector.service';
import { PersonService } from '../../../services/person/person.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { UserService } from '../../../services/user/user.service';
import { RolesService } from '../../../services/roles/roles.service';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {LoadingService} from '../../../services/loading.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  paramsId: number;
  availableRoles: any;
  availableSectors: any;
  selectedRoles: any;
  sectorsAndRoles: any;
  vatNumber: number;
  personId: number;
  person: any;
  selectedUser: any;
  personInfo: any;
  userRoles: any;
  a: any;
  isLoading: boolean;
  isDisabledCropVisible: boolean;
  isDisabledIrrigationVisible: boolean;

  userForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    hint: new FormControl(''),
    vatNumber: new FormControl('', ),
    userRoles: new FormControl([], Validators.required),
    supervisorSectors: new FormControl([]),
    hydronomeasSectors: new FormControl([]),
    viewonlySectors: new FormControl([]),
    isDisabledCrop: new FormControl('', Validators.required),
    isDisabledIrrigation: new FormControl('', Validators.required),
  });

  constructor(private sectorService: SectorService,
              private personService: PersonService,
              private snackbarService: SnackbarService,
              private userService: UserService,
              private roleService: RolesService,
              public router: Router,
              public route: ActivatedRoute,
              public location: Location,
              private formBuilder: FormBuilder,private loadingService: LoadingService) {
    this.selectedRoles = [];
    this.sectorsAndRoles = [];
    this.a = [];
    this.isLoading = false;
    this.isDisabledCropVisible = false;
    this.isDisabledIrrigationVisible = false;
  }

  async ngOnInit() {
    this.loadingService.setLoading(true);
    this.route.params.subscribe((params) => {
      this.paramsId = +params.id;
    });
    this.availableRoles = await this.roleService.fetchAll();
    this.availableRoles.map(e => this.a.push({ role_id: e.id, name: e.name })); // rename role's object key from id to role_id
    this.availableSectors = await this.sectorService.getSectors();
    this.selectedUser = await this.userService.getUserById(+this.paramsId);
    this.personInfo = await this.personService.getPersonById(this.selectedUser.person_id);
    this.selectedRoles = this.selectedUser.roles;

    const roles = [];
    for (const role of this.selectedUser.roles) {
      roles.push(role.role_id);
    }
    if (roles.includes(4) || roles.includes(1)){
      this.isDisabledCropVisible = true;
    }
    if (roles.includes(3) || roles.includes(1)){
      this.isDisabledIrrigationVisible = true;
    }
    // fill form-fields with existing info
    if (this.selectedUser && this.personInfo) {
      this.userForm.controls.username.setValue(this.selectedUser.username);
      this.userForm.controls.hint.setValue(this.selectedUser.hint);
      this.userForm.controls.vatNumber.setValue(this.personInfo.vat_number);
      this.userForm.controls.userRoles.setValue(this.selectedRoles);
      this.userForm.controls.isDisabledCrop.setValue(this.selectedUser.is_disabled_crop);
      this.userForm.controls.isDisabledIrrigation.setValue(this.selectedUser.is_disabled_irrigation);

      // its simple
      const sp = [];
      const hm = [];
      const vo = [];
      this.selectedRoles.forEach((e, i, ar) => {
        e.role_id === 3 ? ar[i].sectors.forEach(e => this.availableSectors.forEach(b => b.id == e.sector_id ? sp.push(b) : false)) :
          e.role_id === 4 ? ar[i].sectors.forEach(e => this.availableSectors.forEach(b => b.id == e.sector_id ? hm.push(b) : false)) :
            e.role_id === 6 ? ar[i].sectors.forEach(e => this.availableSectors.forEach(b => b.id == e.sector_id ? vo.push(b) : false)) :
              false;
      });
      this.userForm.controls.supervisorSectors.setValue(sp);
      this.userForm.controls.hydronomeasSectors.setValue(hm);
      this.userForm.controls.viewonlySectors.setValue(vo);
      this.loadingService.setLoading(false);
    }
  }
  compareFn(o1: any, o2: any) {
    return o1.role_id == o2.role_id;
  }
  /**
   * Description: get all checked roles and display sectors for specific roles
   * @param event check if item is checked
   * @param roleId number -> role_id
   */
  // getSelectedRoles(event, roleId: number) {
  //   console.log(event)
  //   if (event.checked) {
  //     if (roleId === 3 || roleId === 4) {
  //       const model = {
  //         role_id: roleId,
  //         sector_ids: []
  //       };
  //       this.sectorsAndRoles.push(model);
  //       // get selectedRoles length for displaying sectors only for role_id 3 or 4
  //       this.selectedRoles.push(model);
  //     } else {
  //       const model = {
  //         role_id: roleId,
  //         sector_ids: null
  //       };
  //       this.sectorsAndRoles.push(model);
  //     }
  //   } else {
  //     // when unchecked remove record from table
  //     if (roleId === 3 || roleId === 4) {
  //       const currentIndex = this.sectorsAndRoles.findIndex(role => role.id === roleId);
  //       if (currentIndex === -1) {
  //         this.sectorsAndRoles.splice(currentIndex, 1);
  //         // remove from selectedRoles to hide sectors for this role
  //         this.selectedRoles.splice(currentIndex, 1);
  //       }
  //     } else {
  //       this.sectorsAndRoles.forEach((element, index) => {
  //         if (element.role_id === roleId) {
  //           this.sectorsAndRoles.splice(index, 1);
  //         }
  //       });
  //     }
  //   }
  // }

  // setSectorsToRole(event, roleId: number, sectorId: number) {
  //   if (event.checked) {
  //     if (roleId === 3 || roleId === 4) {
  //       const model = {
  //         role_id: roleId,
  //         sector_ids: []
  //       };
  //       // if role_id exists in table just add the sectors
  //       const existsIndex = this.sectorsAndRoles.findIndex(sId => sId.role_id === roleId);
  //       if (existsIndex !== -1) {
  //         this.sectorsAndRoles[existsIndex].sector_ids.push(sectorId);
  //       } else {  // add new record if not exists
  //         this.sectorsAndRoles.push(model);
  //         const index = this.sectorsAndRoles.length - 1;
  //         this.sectorsAndRoles[index].sector_ids.push(sectorId);
  //       }
  //     }
  //   } else {  // remove unchecked sector from table
  //     if (roleId === 3 || roleId === 4) {
  //       for (const items of this.sectorsAndRoles) {
  //         items.sector_ids.forEach((element, index) => {
  //           if (element === sectorId) {
  //             items.sector_ids.splice(index, 1);
  //           }
  //         });
  //       }
  //     }
  //   }
  // }
  /**
   * search person by vat_number. If exists you can continue, else create person first
   */
  async searchPerson() {
    this.person = await this.personService.searchPerson('vat_number', this.vatNumber);
    if (this.person.length > 0) {
      this.personId = this.person[0].id;
    } else {
      this.snackbarService.toastMessagesFailure('Δε βρέθηκε το ΑΦΜ. Παρακαλώ προσθέστε στο μητρώο και μετά δημιουργήστε το χρήστη.');
    }
  }

  displaySectorSupervisorSelection(): boolean {
    if (this.userForm.controls.userRoles.value.some(role => role.id === 3 || role.role_id === 3)) {
      this.userForm.controls.supervisorSectors.enable();
      return true;
    }
    this.userForm.controls.supervisorSectors.disable();
    return false;
  }

  displaySectorHydronomeaSelection(): boolean {
    if (this.userForm.controls.userRoles.value.some(role => role.id === 4 || role.role_id === 4)) {
      this.userForm.controls.hydronomeasSectors.enable();
      return true;
    }
    this.userForm.controls.hydronomeasSectors.disable();
    return false;
  }

  displaySectorViewOnlySelection(): boolean {
    if (this.userForm.controls.userRoles.value.some(role => role.id === 6 || role.role_id === 6)) {
      this.userForm.controls.viewonlySectors.enable();
      return true;
    }
    this.userForm.controls.viewonlySectors.disable();
    return false;
  }

  /**
   * save user edited data
   */
  async editUser(item?: number) {
    let isActive;
    if (item !== undefined) {
      isActive = item;
    } else {
      isActive = this.selectedUser.is_active;
    }

    this.isLoading = true;
    const form = this.userForm.value;
    const obj = [];
    form.userRoles.forEach(e => {
      obj.push({
        role_id: e.role_id,
        ...({}) && { sector_ids: [] },
        ...(e.role_id === 3) && { sector_ids: form.supervisorSectors.map(e => e.id) },
        ...(e.role_id === 4) && { sector_ids: form.hydronomeasSectors.map(e => e.id) },
        ...(e.role_id === 6) && { sector_ids: form.viewonlySectors.map(e => e.id) },
      });
    });
    const userObj = {
      id: this.paramsId,
      personId: this.personInfo.id,
      isActive,
      username: form.username,
      canIrrigatorRequest: this.selectedUser.can_hydronomeas_group,
      canHydronomeasGroup: this.selectedUser.can_irrigator_request,
      roleProperties: obj,
      isDisabledCrop: this.userForm.controls.isDisabledCrop.value,
      isDisabledIrrigation: this.userForm.controls.isDisabledIrrigation.value
    };
    if (this.userForm.valid) {
      try {
        this.loadingService.setLoading(true);
        await this.userService.updateUser(userObj);
      } catch (e) {
        console.log(e.message);
      } finally {
        this.loadingService.setLoading(false);
      }
      this.isLoading = false;
      this.selectedUser = await this.userService.getUserById(+this.paramsId);
    }
  }

  async changeUserStatus() {
    let isActive;
    if (this.selectedUser.is_active === 1) {
      isActive = 0;
      await this.editUser(isActive);
    } else {
      isActive = 1;
      await this.editUser(isActive);
    }
  }

  isUserActive() {
    return this.selectedUser?.is_active === 1;
  }

  deleteUser() {
    this.userService.deleteUser(this.paramsId);
    this.location.back();
    // todo dialog for confirm
  }

  onChange($event: any, formControlName: string) {
    if ($event.checked) {
      this.userForm.get(formControlName).setValue(1);
    } else {
      this.userForm.get(formControlName).setValue(0);
    }
  }
}
