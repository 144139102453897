import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {MatPaginator} from '@angular/material/paginator';
import {Location} from '@angular/common';
import {UserService} from './user/user.service';
import {Router} from '@angular/router';
import {LoadingService} from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  apiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient,
              public location: Location,
              private loadingService: LoadingService,
              private router: Router) {
  }

  getRequest(endpoint: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(`${endpoint}`).subscribe(data => {
        resolve(data);
      }, error => {
        if (error.status === 440) {
          this.logout();
        } else {
          reject(error);
        }
      });
    });
  }

  getFileRequest(endpoint: string) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.httpClient.get(endpoint, httpOptions);
  }

  postRequest(endpoint, model): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(`${endpoint}`, model).subscribe(data => {
        resolve(data);
      }, error => {
        if (error.status === 440) {
          this.logout();
        } else {
          reject(error);
        }
      });
    });
  }

  putRequest(endpoint, model): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.put(`${endpoint}`, model).subscribe(data => {
        resolve(data);
      }, error => {
        if (error.status === 440) {
          this.logout();
        } else {
          reject(error);
        }
      });
    });
  }

  deleteRequest(endpoint): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.delete(`${endpoint}`).subscribe(data => {
        resolve(data);
      }, error => {
        if (error.status === 440) {
          this.logout();
        } else {
          reject(error);
        }
      });
    });
  }

  public setPaginatorTexts(paginator: MatPaginator): MatPaginator {
    paginator._intl.itemsPerPageLabel = 'Εγγραφές Ανά Σελίδα';
    paginator._intl.firstPageLabel = 'Πρώτη Σελίδα';
    paginator._intl.lastPageLabel = 'Τελευταία Σελίδα';
    paginator._intl.nextPageLabel = 'Επόμενη Σελίδα';
    paginator._intl.previousPageLabel = 'Προηγούμενη Σελίδα';

    paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 από ${length}`;
      }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;

      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} από ${length}`;
    };

    return paginator;
  }

  isStartDisabled(status: number) {
    return status !== 0;
  }

  isStopDisabled(status: number) {
    return status !== 1;
  }

  isEditDisabled(status: number) {
    return status !== 1;
  }


  async back() {
    await this.location.back();
  }

  public logout(): void {
    localStorage.clear();
    this.router.navigate(['login']);
    this.loadingService.setLoading(false);
  }
}
