<div fxLayout="column" fxFlex="100" style="height: 100vh;" class="mainHolder">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">

    <div fxLayout="column" class="mat-elevation-z3 loginCard" fxLayoutGap="15px"
         fxFlex.lt-sm="75" fxFlex.sm="55" fxFlex.gt-sm="50"
         fxFlex.lt-md="50" fxFlex.md="45" fxFlex.gt-md="45"
         fxFlex.lt-lg="45" fxFlex.lg="25" fxFlex.gt-lg="20">
      <!--      header-->
      <div fxLayout="row" fxLayoutAlign="center center">
        <img src="assets/general/logo.png" alt="" style="width: 70px;height: 70px; aspect-ratio: 2/1;">
      </div>

      <!--      username-->
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">

        <div fxFlex="30">Όνομα χρήστη:</div>
        <div fxFlex="70">
          <mat-form-field fxFlex="100">
            <input matInput [(ngModel)]="user.username"/>
          </mat-form-field>
        </div>

      </div>
      <!--      password-->
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
        <div fxFlex="30">Συνθηματικό:</div>
        <div fxFlex="70">
          <mat-form-field fxFlex="100">
            <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="user.password" (keyup.enter)="userLogin()"/>
            <mat-icon style="cursor: pointer" (click)="hide = !hide"
                      matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <!--      login button-->
      <div fxLayout="row" fxFlex="100"></div>
      <button style="border-radius: 10px;" mat-flat-button (click)="userLogin()">Σύνδεση</button>
    </div>


  </div>
</div>
