<div fxLayout="column" class="container">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxFlex="90">
      <h1>Χρήστες</h1>
    </div>

    <div fxLayout="row" fxLayoutGap="5px">
    <button class="app-button" mat-button color="primary" (click)="navigateToUserCreationPage()" matTooltip="Προσθήκη χρήστη">
      <mat-icon>add</mat-icon>
    </button>

    <button [disabled]="isButtonDisabled()" class="app-button" mat-icon-button color="primary" (click)="exportToExcel('Users')" matTooltip="Εξαγωγή σε excel">
      <mat-icon>file_download</mat-icon>
    </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div fxFlex="100">
    <app-table #table [columns]="displayColumns" [model]="model" [hasNavigation]="false" [callBack]="navigateToEditUserPage"></app-table>
  </div>
</div>
