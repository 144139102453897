<div fxLayout="column">
  <div fxLayout="row" style="margin-left: 2%;margin-top: 2%;">
    <h1>Προσθήκη ατόμου στο μητρώο</h1>
  </div>

  <div style="margin: 1%;">
    <mat-divider></mat-divider>
    </div>

  <div fxLayout="row wrap" style="margin-left: 2%;">

    <app-person-form style="width: 100%;" [personId]="null" [mode]="'create'"></app-person-form>

  </div>
</div>
