<div fxLayout="column" fxLayoutGap="20px">
<mat-card *ngFor="let table of tables">
  <mat-card-title>{{table.title}}</mat-card-title>
  <mat-card-subtitle>{{table.subtitle}}</mat-card-subtitle>
  <div *ngIf="table.notes" fxLayout="row" fxLayoutGap="5px">
    <mat-icon>info</mat-icon>
    <div class="notes">{{table.notes}}</div>
  </div>
  <mat-card-content>
    <span>
      <ul>
        <li *ngFor="let c of table.data.columns">{{c.name}} {{c.description}}</li>
      </ul>
    </span>
  </mat-card-content>
</mat-card>
</div>
