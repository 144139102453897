import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../../../services/loading.service';
import { ToastMessagesService } from '../../../../services/toast-messages.service';
import { FieldService } from '../../../../services/fields/field.service';
import { CommonService } from '../../../../services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmOptionDialogComponent } from '../../shared/confirm-option-dialog/confirm-option-dialog.component';
import { FieldActionsDialogComponent } from "./field-actions-dialog/field-actions-dialog.component";

@Component({
  selector: 'app-single-field',
  templateUrl: './single-field.component.html',
  styleUrls: ['./single-field.component.scss']
})
export class SingleFieldComponent implements OnInit {
  fieldId: number;
  field: any;
  status: number;
  pending: boolean;
  methods: any[];
  causes: [];

  constructor(public route: ActivatedRoute,
    public dialog: MatDialog,
    public commonService: CommonService,
    public toastMessageService: ToastMessagesService,
    private fieldService: FieldService,
    public loadingService: LoadingService) {
    this.pending = true;
    this.status = 0;
  }

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      this.fieldId = +params.id;
      this.field = await this.initField();
      this.methods = await this.fieldService.irrigationMethods();
      this.causes = await this.fieldService.causeOfPause();
    });
    const options = JSON.parse(localStorage.getItem('squareOptions'));
    this.status = options.isActive;
  }

  async initField() {
    try {
      this.loadingService.setLoading(true);
      const fields = await this.fieldService.fetchFieldById(this.fieldId);
      console.log(fields)
      return fields;
    } catch (e) {
      this.toastMessageService.toastErrorMessage(e.message);
    } finally {
      this.loadingService.setLoading(false);
      this.pending = false;
    }
  }


  start() {
    const dialog = this.dialog.open(FieldActionsDialogComponent, {
      data: {
        title: 'Έναρξη άρδευσης',
        field: this.field,
        methods: this.methods,
        mode: 'start',
      }
    });

    dialog.afterClosed().subscribe(async response => {
      if (response) {
        // if hasRequestByIrrigator init start with approval of 0
        if(this.hasRequestByIrrigator()){
          const object = {
            irrigation_id: this.field.irrigation_id,
            is_approved: 0
          }
          try {
            this.loadingService.setLoading(true);
            await this.commonService.putRequest(`irrigation/approveRequest`, object);
          } catch (e) {
            this.toastMessageService.toastErrorMessage(e.message);
          } finally {
            this.loadingService.setLoading(false);
          }
          
        }
        this.field = await this.initField();
      }
    });
  }

  edit() {

  }

  stop() {
    const dialog = this.dialog.open(FieldActionsDialogComponent, {
      data: {
        title: 'Λήξη άρδευσης',
        field: this.field,
        methods: this.methods,
        mode: 'stop',
      }
    });

    dialog.afterClosed().subscribe(async response => {
      if (response) {
        this.field = await this.initField();
      }
    });
  }

  pause() {
    const dialog = this.dialog.open(FieldActionsDialogComponent, {
      data: {
        title: 'Παύση άρδευσης',
        field: this.field,
        methods: this.methods,
        mode: 'pause',
        causes: this.causes
      }
    });

    dialog.afterClosed().subscribe(async response => {
      if (response) {
        this.field = await this.initField();
      }
    });
  }

  restart() {
    const dialog = this.dialog.open(FieldActionsDialogComponent, {
      data: {
        title: 'Επανέναρξη άρδευσης',
        field: this.field,
        methods: this.methods,
        mode: 'restart',
      }
    });

    dialog.afterClosed().subscribe(async response => {
      if (response) {
        this.field = await this.initField();
      }
    });
  }

  hasStarted() {
    const flag = this.hasRequestByIrrigator() ? this.field?.is_approved_by_hydronomea === 1 : this.field?.hasActiveIrrigation === 1
    return flag
  }
  hasRequestByIrrigator() {
    return this.field?.is_request_by_irrigator === 1
  }

  isStartDisabled() {
    return this.field?.is_position_confirmed_by_hydronomea === 0 || this.hasStarted()
  }

  isPausedDisabled() {
    return this.field?.hasActiveIrrigation === 0 || (!this.field?.is_approved_by_hydronomea && this.field?.is_request_by_irrigator === 1 && this.field?.hasActiveIrrigation === 1) || this.field?.is_paused === 1
  }

  isRestartDisabled() {
    return !this.field || !this.field.is_paused;
  }

  isStopDisabled() {
    return this.field?.hasActiveIrrigation === 0 || (!this.field?.is_approved_by_hydronomea && this.field?.is_request_by_irrigator === 1 && this.field?.hasActiveIrrigation === 1)
  }

  isApprovalDisabled(field: any) {
    return field?.is_position_confirmed_by_hydronomea === 0 || this.hasStarted() || !field?.is_request_by_irrigator
  }

  finalize() {
    const final = this.dialog.open(ConfirmOptionDialogComponent, {
      disableClose: true,
      data: {
        title: `Οριστικοποίηση θέσης`,
        message: `Επιλέξατε να οριστικοποιήσετε τη θέση σας. Θέλετε να συνεχίσετε?`,
        YesBtn: `Ναι`,
        NoBtn: `Ακύρωση`,
        hasDecline: false
      }
    });

    final.afterClosed().subscribe(async (response) => {
      if (response) {
        try {
          this.loadingService.setLoading(true);
          await this.commonService.putRequest(`field/confirmPositionByHydronomea/${this.fieldId}`, {});
          this.toastMessageService.toastMessages(`Η οριστικοποίηση ολοκληρώθηκε με επιτυχία`);
          this.field = await this.initField();
        } catch (e) {
          this.toastMessageService.toastErrorMessage(e.message);
        } finally {
          this.loadingService.setLoading(false);
        }
      }
    });
  }

  approve() {
    const final = this.dialog.open(ConfirmOptionDialogComponent, {
      disableClose: true,
      data: {
        title: `Έγκριση`,
        message: `Επιλέξατε να εγκρίνετε την αίτηση του αρδευτή. Θέλετε να συνεχίσετε?`,
        YesBtn: `Ναι`,
        NoBtn: `Ακύρωση`,
        hasDecline: false
      }
    });

    final.afterClosed().subscribe(async (response) => {
      const object = {
        irrigation_id: this.field.irrigation_id,
        is_approved: response ? 1 : 0
      };
      try {
        this.loadingService.setLoading(true);
        await this.commonService.putRequest(`irrigation/approveRequest`, object);
        this.toastMessageService.toastMessages(`Η έγκριση ολοκληρώθηκε με επιτυχία`);
        this.field = await this.initField();
      } catch (e) {
        this.toastMessageService.toastErrorMessage(e.message);
      } finally {
        this.loadingService.setLoading(false);
      }
    });
  }
}