<div fxLayout="column" class="container">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxFlex="100">
      <h1>Προσθήκη χρήστη</h1>
    </div>
    <button [disabled]="isFormInvalid() || userExist" class="app-button" mat-icon-button color="primary"
            (click)="submitUser()" matTooltip="Αποθήκευση">
      <mat-icon>done</mat-icon>
    </button>
  </div>

  <div style="margin: 1%;">
    <mat-divider></mat-divider>
  </div>

  <div fxLayout="row">
    <div fxLayout="column">
      <div fxLayout="row wrap" fxLayoutAlign="start center" style="margin-top: 1rem;">

        <div fxLayout="column">
          <h3>Χρήστης στο μητρώο*</h3>
          <mat-form-field appearance="outline">
            <mat-label>Αναζήτηση με ΑΦΜ</mat-label>
            <input matInput placeholder="Αναζήτηση με ΑΦΜ" [(ngModel)]="vatNumber" type="string" maxlength = "9">
          </mat-form-field>
        </div>
        <div fxFlexAlign="flex-end center">
          <button mat-button (click)="searchPerson()" [disabled]="vatNumber === undefined">
            <mat-icon>search</mat-icon>
          </button>
        </div>

      </div>

      <div fxLayout="row" *ngIf="person && person.length > 0" fxLayoutAlign="start center center">
        <div fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>Όνομα</mat-label>
            <input matInput placeholder="Όνομα χρήστη" readonly [(ngModel)]="person[0].firstname">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Επώνυμο</mat-label>
            <input matInput placeholder="Όνομα χρήστη" readonly [(ngModel)]="person[0].lastname">
          </mat-form-field>

        </div>
        <div>
          <mat-icon style="color: green;">done</mat-icon>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayoutGap="15px" *ngIf="person && person.length > 0">

        <mat-form-field appearance="outline">
          <mat-label>Όνομα χρήστη</mat-label>
          <input matInput placeholder="Όνομα χρήστη" [(ngModel)]="username" type="number" oninput="this.value=this.value.slice(0,this.maxLength)" maxlength="10">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Ρόλοι</mat-label>
          <mat-select (selectionChange)="overViewRoles()" multiple [(ngModel)]="userRoles">
            <mat-option [disabled]="displaySectorViewOnlySelection() && role.id !== 6" *ngFor="let role of availableRoles" [value]="role">{{role.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="displaySectorSupervisorSelection()">
          <mat-label>Τομείς επόπτη</mat-label>
          <mat-select multiple [(ngModel)]="superVisorSectors">
            <mat-option *ngFor="let sector of availableSectors" [value]="sector">{{sector.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="displaySectorHydronomeaSelection()">
          <mat-label>Τομείς υδρονομέα</mat-label>
          <mat-select multiple [(ngModel)]="hydronomeasSectors">
            <mat-option *ngFor="let sector of availableSectors" [value]="sector">{{sector.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="displaySectorViewOnlySelection()">
          <mat-label>Τομείς επισκέπτη</mat-label>
          <mat-select multiple [(ngModel)]="viewOnlySectors">
            <mat-option *ngFor="let sector of availableSectors" [value]="sector">{{sector.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" *ngIf="displayIrrigatorOptions()">
        <mat-slide-toggle [(ngModel)]="canIrrigatorRequest">Αίτηση αρδευτή</mat-slide-toggle>
      </div>

      <div fxLayout="row" *ngIf="displaySectorHydronomeaSelection()">
        <mat-slide-toggle [(ngModel)]="canHydronomeasGroup">Ομαδοποίηση υδρονομέα</mat-slide-toggle>
      </div>

      <div fxLayout="row" *ngIf="person && person.length > 0">
        <mat-slide-toggle (change)="getPassword()" [(ngModel)]="autoPassword">{{autoPassword ? 'Απενεργοποίηση αυτόματου κωδικού' : 'Ενεργοποίηση αυτόματου κωδικού'}}</mat-slide-toggle>
      </div>

      <div fxLayout="row" fxLayoutGap="15px" *ngIf="person && person.length > 0">
        <mat-form-field appearance="outline">
          <mat-label>Συνθηματικό</mat-label>
          <input matInput [(ngModel)]="password">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Υπενθύμιση συνθηματικού</mat-label>
          <input readonly matInput [(ngModel)]="hint">
        </mat-form-field>
      </div>

      <div fxLayout="row" *ngIf="person && person.length > 0">
        <div fxLayout="column" fxLayoutGap="10px">
          <mat-slide-toggle color="primary" (change)="onChange($event, 'isDisabledCrop')"
                            [(ngModel)]="isDisabledCrop">Απενεργοποίηση επεξεργασίας καλλιέργειας</mat-slide-toggle>
          <mat-slide-toggle color="primary" (change)="onChange($event, 'isDisabledIrrigation')"
                            [(ngModel)]="isDisabledIrrigation">Απενεργοποίηση επεξεργασίας άρδευσης</mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>


</div>
