import {Component, OnInit} from '@angular/core';
import {LoginUser} from '../../models/LoginUser';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user/user.service';
import {LoadingService} from '../../../services/loading.service';
import {ToastMessagesService} from '../../../services/toast-messages.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  user: LoginUser;
  hide: boolean;
  localUser: any;

  constructor(private router: Router,
              private userService: UserService,
              public toastMessageService: ToastMessagesService,
              private loadingService: LoadingService) {
    this.hide = true;
    this.user = {
      username: '',
      password: ''
    };
  }

  async ngOnInit(): Promise<void> {
    try {
      this.localUser = this.userService.validateUser();
      if (this.localUser) {
        await this.router.navigate(['start/home']);
      }
    } catch (e) {
      this.toastMessageService.toastErrorMessage(e.message);
    }
  }

  /**
   * handle user login and display messages to user
   */
  async userLogin() {
    try {
      this.loadingService.setLoading(true);
      const response = await this.userService.login(this.user);
      localStorage.setItem('token', response);
      const user = await this.userService.getUserByToken();
      if (user && user.roles.some(role => role.role_id === 1 || role.role_id === 2)) {
        await this.router.navigate(['start/home']);
      } else {
        localStorage.removeItem('token');
        this.toastMessageService.toastErrorMessage('Δεν έχετε πρόσβαση στην εφαρμογή!');
      }
    } catch (e) {
      console.log(e);
      this.toastMessageService.toastErrorMessage('Λανθασμένα στοιχεία εισόδου!');
    } finally {
      this.loadingService.setLoading(false);
    }
  }
}
