import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Log} from '../../models/Log';
import {LogService} from '../../../services/log.service';
import {MatPaginator} from '@angular/material/paginator';
import {CommonService} from '../../../services/common.service';
import {LoadingService} from '../../../services/loading.service';
import {ToastMessagesService} from '../../../services/toast-messages.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent {
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  displayColumns = [{key: 'user_id', label: 'Αναγνωριστικό'}, {key: 'firstname', label: 'Όνομα'}, {
    key: 'lastname',
    label: 'Επώνυμο'
  }, {key: 'name', label: 'Ενέργεια'}, {key: 'message', label: 'Μήνυμα'}, {key: 'action_date', label: 'Ημ. Ενέργειας'}];
  model = 'logs';
  actionIcon = 'download';

  constructor(private logService: LogService,
              private loadingService: LoadingService,
              public toastMessageService: ToastMessagesService) {
  }

  async export() {
    try {
      this.loadingService.setLoading(true);
      const url = await this.logService.exportData();
      window.open(url);
    } catch (e) {
      this.toastMessageService.toastErrorMessage(e.message);
    } finally {
      this.loadingService.setLoading(false);
    }
  }
}
