import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  durationInSeconds = 5;

  constructor(private snackBar: MatSnackBar) { }

  public toastMessagesSuccess(message: string): void {
    this.snackBar.open(message, 'Κλείσιμο', {
      duration: this.durationInSeconds * 1000,
      panelClass: ['snackbar-custom-success']
    });
  }

  public toastMessagesFailure(message: string): void {
    this.snackBar.open(message, 'Κλείσιμο', {
      duration: this.durationInSeconds * 1000,
      panelClass: ['snackbar-custom-failure']
    });
  }
}
