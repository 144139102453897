import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../../../services/user/user.service';
import {ToastMessagesService} from '../../../../services/toast-messages.service';
import * as moment from 'moment';
import {NotificationsService} from '../../../../services/notifications.service';

@Component({
  selector: 'app-create-notification',
  templateUrl: './create-notification.component.html',
  styleUrls: ['./create-notification.component.scss']
})
export class CreateNotificationComponent implements OnInit {
  pending: boolean;
  users: any[] = [];
  selectedUsers: any[] = [];
  message: string;
  editorStyle: any;
  searchTxt: string;
  title: string;
  minDate: any;
  maxDate: any;
  stepHour: any;
  stepMinute: any;
  stepSecond: any;
  sendDate: any;
  isLoading: boolean;
  text: any;

  constructor(public dialogRef: MatDialogRef<CreateNotificationComponent>,
              private userService: UserService,
              private notificationService: NotificationsService,
              public toastMessagesService: ToastMessagesService) {
    this.pending = true;
    this.message = '';
    this.editorStyle = {height: '250px'};
    this.stepMinute = 1;
    this.stepHour = 1;
    const now = new Date();
    this.minDate = new Date();
    this.minDate.setDate(now.getDate());
    this.isLoading = false;
  }


  async ngOnInit(): Promise<void> {
    try {
      this.users = await this.initUsers();
      this.pending = false;
    } catch (e) {
      this.toastMessagesService.toastErrorMessage(e.message);
    }
  }


  async initUsers() {
    return await this.userService.getUsers();
  }


  close() {
    this.dialogRef.close();
  }

  changeEvent() {
  }

  async submit() {
    const objectToSend = {
      title: this.title,
      text: this.message.replace(/<(?:.|\n)*?>/gm, ' ').replace('\n', '').trim(),
      users: this.selectedUsers.map(user => user.id),
      sendDate: this.sendDate ? moment(new Date(this.sendDate)).format('YYYY-MM-DD HH:mm') : null
    };
    
    try {
      this.isLoading = true;
      await this.notificationService.create(objectToSend);
      this.toastMessagesService.toastMessages('Η ειδοποίηση δημιουργήθηκε με επιτυχία');

      this.dialogRef.close();
    } catch (e) {
      this.toastMessagesService.toastErrorMessage(e.message);
    } finally {
      this.isLoading = false;
    }
  }


  shouldBeDisabled() {
    return !this.title || !this.message || this.selectedUsers.length === 0;
  }
}
