<div mat-dialog-title fxLayout='row' style="justify-content: space-between;
  align-items: flex-start;margin-bottom: 0;">
  <h2 mat-dialog-title>{{title}}</h2>
  <button mat-button class="close-icon" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div>
    <p>Οι επιλεγμένοι χρήστες θα έχουν απενεργοποιημένη την ενέργεια.</p>
  </div>
  <div>
    <mat-checkbox color="primary" [(ngModel)]="selectAll" (click)="selectAllUsers()">Επιλογή όλων</mat-checkbox>
    <div *ngFor="let user of users">
      <mat-checkbox color="primary" [(ngModel)]="user.is_disabled"> {{user.name}}</mat-checkbox>
    </div>
  </div>
</div>
<div mat-dialog-actions style="justify-content: flex-end;">
  <button mat-raised-button (click)="saveConfiguration()">
    Αποθήκευση
  </button>
</div>


