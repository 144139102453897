import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {SectorService} from '../../../services/sectors/sector.service';
import {PersonService} from '../../../services/person/person.service';
import {SnackbarService} from '../../../services/snackbar/snackbar.service';
import {UserService} from '../../../services/user/user.service';

@Component({
  selector: 'app-user-creation-dialog',
  templateUrl: './user-creation-dialog.component.html',
  styleUrls: ['./user-creation-dialog.component.scss']
})
export class UserCreationDialogComponent implements OnInit {
  availableRoles: any;
  availableSectors: any;
  selectedRoles: any;
  sectorsAndRoles: any;
  vatNumber: number;
  username: string;
  password: string;
  personId: number;
  person: any;

  constructor(private dialogRef: MatDialogRef<UserCreationDialogComponent>,
              private sectorService: SectorService,
              private personService: PersonService,
              private snackbarService: SnackbarService,
              private userService: UserService) {
    this.selectedRoles = [];
    this.sectorsAndRoles = [];
    this.username = '';
    this.password = '';
    this.availableRoles = [
      {
        id: 1,
        value: 1,
        labelText: 'Διαχειριστής'
      },
      {
        id: 2,
        value: 2,
        labelText: 'Προσωπικό ΤΟΕΒ'
      },
      {
        id: 3,
        value: 3,
        labelText: 'Επόπτης'
      },
      {
        id: 4,
        value: 4,
        labelText: 'Υδρονομέας'
      },
      {
        id: 5,
        value: 5,
        labelText: 'Αρδευτής'
      },
    ];

  }

  async ngOnInit() {
    try {
      this.availableSectors = await this.sectorService.getSectors();
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Description: get all checked roles and display sectors for specific roles
   * @param event check if item is checked
   * @param roleId number -> role_id
   */
  getSelectedRoles(event, roleId: number) {
    if (event.checked) {
      if (roleId === 3 || roleId === 4) {
        const model = {
          role_id: roleId,
          sector_ids: []
        };
        this.sectorsAndRoles.push(model);
        // get selectedRoles length for displaying sectors only for role_id 3 or 4
        this.selectedRoles.push(model);
      } else {
        const model = {
          role_id: roleId,
          sector_ids: null
        };
        this.sectorsAndRoles.push(model);
      }
    } else {
      // when unchecked remove record from table
      if (roleId === 3 || roleId === 4) {
        const currentIndex = this.sectorsAndRoles.findIndex(role => role.id === roleId);
        if (currentIndex === -1) {
          this.sectorsAndRoles.splice(currentIndex, 1);
          // remove from selectedRoles to hide sectors for this role
          this.selectedRoles.splice(currentIndex, 1);
        }
      } else {
        this.sectorsAndRoles.forEach((element, index) => {
          if (element.role_id === roleId) {
            this.sectorsAndRoles.splice(index, 1);
          }
        });
      }
    }
  }

  setSectorsToRole(event, roleId: number, sectorId: number) {
    if (event.checked) {
      if (roleId === 3 || roleId === 4) {
        const model = {
          role_id: roleId,
          sector_ids: []
        };
        // if role_id exists in table just add the sectors
        const existsIndex = this.sectorsAndRoles.findIndex(sId => sId.role_id === roleId);
        if (existsIndex !== -1) {
          this.sectorsAndRoles[existsIndex].sector_ids.push(sectorId);
        } else {  // add new record if not exists
          this.sectorsAndRoles.push(model);
          const index = this.sectorsAndRoles.length - 1;
          this.sectorsAndRoles[index].sector_ids.push(sectorId);
        }
      }
    } else {  // remove unchecked sector from table
      if (roleId === 3 || roleId === 4) {
        for (const items of this.sectorsAndRoles) {
          items.sector_ids.forEach((element, index) => {
            if (element === sectorId) {
              items.sector_ids.splice(index, 1);
            }
          });
        }
      }
    }
  }

  /**
   * search person by vat_number. If exists you can continue, else create person first
   */
  async searchPerson() {
    this.person = await this.personService.searchPerson('vat_number', this.vatNumber);
    if (this.person.length > 0) {
      this.personId = this.person[0].id;
      console.log(this.person);
    } else {
      this.snackbarService.toastMessagesFailure('Δε βρέθηκε το ΑΦΜ. Παρακαλώ προσθέστε στο μητρώο και μετά δημιουργήστε το χρήστη.');
    }
  }

  /**
   * create new user for the app
   */
  async addNewUser() {
    console.log(this.sectorsAndRoles);
    if (this.personId) {
      const postModel = {
        username: this.username,
        password: this.password,
        personId: this.personId,
        roleProperties: this.sectorsAndRoles
      };
      if (postModel.username !== '' && postModel.password !== '' && postModel.roleProperties.length > 0) {
        console.log('in');
        const result = await this.userService.createUser(postModel);
        if (result) {
          this.snackbarService.toastMessagesSuccess('Επιτυχής δημιουργία χρήστη');
          this.dialogRef.close();
        }
      }
    } else {
      if (this.vatNumber === undefined) {
        this.snackbarService.toastMessagesFailure('Πρέπει πρώτα να αναζητήσετε τον ΑΦΜ στο μητρώο');
      } else if (this.username === '' || this.password === '') {
        this.snackbarService.toastMessagesFailure('Πρέπει να συμπληρώσετε τα υποχρεωτικά πεδία');
      }
    }
  }
}
