import {Component, OnInit} from '@angular/core';
import {SectorService} from '../../../services/sectors/sector.service';
import {PersonService} from '../../../services/person/person.service';
import {SnackbarService} from '../../../services/snackbar/snackbar.service';
import {UserService} from '../../../services/user/user.service';
import {Router} from '@angular/router';
import {RolesService} from '../../../services/roles/roles.service';
import {ToastMessagesService} from '../../../services/toast-messages.service';
import {LoadingService} from '../../../services/loading.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  availableRoles: any;
  availableSectors: any;
  selectedRoles: any;
  sectorsAndRoles: any;
  vatNumber: string;
  username: string;
  password: string;
  personId: number;
  person: any;
  userRoles: any = [];
  superVisorSectors: any = [];
  hydronomeasSectors: any = [];
  viewOnlySectors: any = [];
  validVat: boolean;
  userExist: boolean;
  hint: string;
  autoPassword: boolean;
  canIrrigatorRequest: boolean;
  canHydronomeasGroup: boolean;
  isViewOnly: boolean;
  isDisabledCrop: number;
  isDisabledIrrigation: number;

  constructor(private sectorService: SectorService,
              private personService: PersonService,
              private snackbarService: SnackbarService,
              private loadingService: LoadingService,
              private userService: UserService,
              private roleService: RolesService,
              public toastMessageService: ToastMessagesService,
              public router: Router) {
    this.validVat = false;
    this.selectedRoles = [];
    this.sectorsAndRoles = [];
    this.username = '';
    this.password = '';
    this.userExist = false;
    this.hint = '';
    this.autoPassword = true;
    this.canHydronomeasGroup = false;
    this.canIrrigatorRequest = false;
    this.isViewOnly = false;
    this.isDisabledCrop = 0;
    this.isDisabledIrrigation = 0;
  }

  async ngOnInit() {
    try {
      this.availableSectors = await this.sectorService.getSectors();
      this.availableRoles = await this.roleService.fetchAll();
    } catch (e) {
      this.toastMessageService.toastErrorMessage(e.error);
    }
  }

  /**
   * Description: get all checked roles and display sectors for specific roles
   * @param event check if item is checked
   * @param roleId number -> role_id
   */
  getSelectedRoles(event, roleId: number) {
    if (event.checked) {
      if (roleId === 3 || roleId === 4) {
        const model = {
          role_id: roleId,
          sector_ids: []
        };
        this.sectorsAndRoles.push(model);
        // get selectedRoles length for displaying sectors only for role_id 3 or 4
        this.selectedRoles.push(model);
      } else {
        const model = {
          role_id: roleId,
          sector_ids: null
        };
        this.sectorsAndRoles.push(model);
      }
    } else {
      // when unchecked remove record from table
      if (roleId === 3 || roleId === 4) {
        const currentIndex = this.sectorsAndRoles.findIndex(role => role.id === roleId);
        if (currentIndex === -1) {
          this.sectorsAndRoles.splice(currentIndex, 1);
          // remove from selectedRoles to hide sectors for this role
          this.selectedRoles.splice(currentIndex, 1);
        }
      } else {
        this.sectorsAndRoles.forEach((element, index) => {
          if (element.role_id === roleId) {
            this.sectorsAndRoles.splice(index, 1);
          }
        });
      }
    }
  }

  setSectorsToRole(event, roleId: number, sectorId: number) {
    if (event.checked) {
      if (roleId === 3 || roleId === 4) {
        const model = {
          role_id: roleId,
          sector_ids: []
        };
        // if role_id exists in table just add the sectors
        const existsIndex = this.sectorsAndRoles.findIndex(sId => sId.role_id === roleId);
        if (existsIndex !== -1) {
          this.sectorsAndRoles[existsIndex].sector_ids.push(sectorId);
        } else {  // add new record if not exists
          this.sectorsAndRoles.push(model);
          const index = this.sectorsAndRoles.length - 1;
          this.sectorsAndRoles[index].sector_ids.push(sectorId);
        }
      }
    } else {  // remove unchecked sector from table
      if (roleId === 3 || roleId === 4) {
        for (const items of this.sectorsAndRoles) {
          items.sector_ids.forEach((element, index) => {
            if (element === sectorId) {
              items.sector_ids.splice(index, 1);
            }
          });
        }
      }
    }
  }

  /**
   * search person by vat_number. If exists you can continue, else create person first
   */
  async searchPerson() {
    this.person = await this.personService.searchPerson('vat_number', this.vatNumber);
    if (this.person.length > 0) {
      this.personId = this.person[0].id;
      console.log(this.person);
      this.validVat = true;
      this.getPassword()
      const user = await this.userService.fetchUserByPersonId(this.personId);
      if (user) {
        this.userExist = true;
        this.toastMessageService.toastMessages('Υπάρχει ήδη χρήστης με αυτό το Α.Φ.Μ');
        return;
      } else {
        this.userExist = false;
      }
    } else {
      this.toastMessageService.toastErrorMessage('Δε βρέθηκε το ΑΦΜ. Παρακαλώ προσθέστε στο μητρώο και μετά δημιουργήστε το χρήστη.');
    }
  }

  //#region Visibility Options
  displaySectorSupervisorSelection(): boolean {
    return this.userRoles.some(role => role.id === 3);
  }

  displaySectorHydronomeaSelection(): boolean {
    return this.userRoles.some(role => role.id === 4);
  }

  displayIrrigatorOptions(): boolean {
    return this.userRoles.some(role => role.id === 5);
  }

  displaySectorViewOnlySelection() {
    return this.userRoles.some(role => role.id === 6);
  }

  //#endregion

  async submitUser() {
    try {
      this.loadingService.setLoading(true);
      const postModel = {
        username: this.username,
        password: this.password,
        personId: this.personId,
        hint: this.hint,
        canIrrigatorRequest: this.canIrrigatorRequest ? 1 : 0,
        canHydronomeasGroup: this.canHydronomeasGroup ? 1 : 0,
        roleProperties: this.distributeSectorsToRoles(),
        isDisabledCrop: this.isDisabledCrop,
        isDisabledIrrigation: this.isDisabledIrrigation
      };

      await this.userService.createUser(postModel);
      this.snackbarService.toastMessagesSuccess('Επιτυχής δημιουργία χρήστη');
      await this.router.navigate(['start/users']);

    } catch (e) {
      console.log(e);
      this.toastMessageService.toastErrorMessage(e.error.message);
    } finally {
      this.loadingService.setLoading(false);
    }
  }

  distributeSectorsToRoles() {
    const rolesWithSectors = [];

    if (this.displaySectorHydronomeaSelection() || this.displaySectorSupervisorSelection() || this.displaySectorViewOnlySelection()) {
      for (const role of this.userRoles) {
        if (role.id === 3 || role.id === 4 || role.id === 6) {
          const model = {
            role_id: role.id,
            sector_ids: role.id === 3 ? this.superVisorSectors.map(s => s.id) :
              role.id === 4 ? this.hydronomeasSectors.map(s => s.id) : this.viewOnlySectors.map(s => s.id)
          };
          rolesWithSectors.push(model);
        } else {
          const model = {
            role_id: role.id,
            sector_ids: []
          };
          rolesWithSectors.push(model);
        }
      }
    } else {
      for (const role of this.userRoles) {
        const model = {
          role_id: role.id,
          sector_ids: []
        };
        rolesWithSectors.push(model);
      }
    }
    return rolesWithSectors;
  }

  /**
   * create new user for the app
   */
  async addNewUser() {
    console.log(this.sectorsAndRoles);
    if (this.personId) {
      const postModel = {
        username: this.username,
        password: this.password,
        personId: this.personId,
        roleProperties: this.sectorsAndRoles
      };
      if (postModel.username !== '' && postModel.password !== '' && postModel.roleProperties.length > 0) {
        const result = await this.userService.createUser(postModel);
        if (result) {
          this.snackbarService.toastMessagesSuccess('Επιτυχής δημιουργία χρήστη');
        }
        await this.router.navigate(['start/users']);
      } else {
        this.snackbarService.toastMessagesFailure('Πρέπει να συμπληρώσετε τα υποχρεωτικά πεδία');
      }
    } else {
      if (this.vatNumber === undefined) {
        this.snackbarService.toastMessagesFailure('Πρέπει πρώτα να αναζητήσετε τον ΑΦΜ στο μητρώο');
      } else if (this.username === '' || this.password === '') {
        this.snackbarService.toastMessagesFailure('Πρέπει να συμπληρώσετε τα υποχρεωτικά πεδία');
      }
    }
  }

  isFormInvalid() {
    return !(this.validVat && this.username && this.password && this.userRoles.length > 0);
  }

  getPassword() {
    if (this.autoPassword) {
      const randomNumbers = [];
      for (let i = 0; i < 4; i++) {
        randomNumbers.push(Math.floor(Math.random() * 9));
      }
      this.hint = randomNumbers.map(n => n + 1).join(',');
      let temp=""
      for (const num of randomNumbers) {
        temp += [...""+this.vatNumber][num];
      }
      this.password = temp
    } else {
      this.clearPassword();
    }
  }

  clearPassword() {
    this.password = '';
    this.hint = '';
  }

  overViewRoles() {
    if (this.userRoles.some(role => role.id === 6)) {
      const roles = this.userRoles.filter(role => role.id === 6);
      this.userRoles = roles;
    }
  }

  onChange($event: any, field: string) {
    if ($event.checked) {
      if (field === 'isDisabledCrop') {
        this.isDisabledCrop = 1;
      } else if (field === 'isDisabledIrrigation') {
        this.isDisabledIrrigation = 1;
      }
    } else {
      if (field === 'isDisabledCrop') {
        this.isDisabledCrop = 0;
      } else if (field === 'isDisabledIrrigation') {
        this.isDisabledIrrigation = 0;
      }
    }
  }
}
