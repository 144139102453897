import {Component, ViewChild} from '@angular/core';

@Component({
  selector: 'app-sectors',
  templateUrl: './sectors.component.html',
  styleUrls: ['./sectors.component.scss']
})
export class SectorsComponent {
  @ViewChild('table', {static: true}) table: any;
  displayColumns = [{key: 'name', label: 'Όνομα τομέα'}, {key: 'is_active', label: 'Ανοιχτός'},
    {key: 'max_open_supply', label: 'Άρδευση'}, {key: 'open_supply', label: 'Παροχή'},
    {key: 'planned_liters', label: 'Προγραμματισμός'}, {key: 'max_supply', label: 'Μέγιστη παροχή'}];
  model = 'sectors';
  hasNavigation = true;
  navigateTo = 'start/squares';
  constructor() {}

  exportToExcel(fileName: string) {
    this.table.exportToExcel(fileName);
  }

  isButtonDisabled() {
    return this.table.dataSource.filteredData.length === 0;
  }
}
