import {Injectable} from '@angular/core';
import {CommonService} from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class SectorService {

  constructor(private commonService: CommonService) { }

  async getSectors() {
    return await this.commonService.getRequest(`sectors`);
  }
}
