<div fxLayout="column" class="container">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" style="margin-top: 1rem;">
    <div fxFlex="100">
      <h1>Αγροτεμάχια τετραγώνου {{squareName}}</h1>
    </div>

    <button [disabled]="isButtonDisabled()" class="app-button" mat-icon-button color="primary" (click)="exportToExcel('ActiveIrrigation')" matTooltip="Εξαγωγή σε excel">
      <mat-icon>file_download</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>

  <div fxFlex="100">
    <app-table #table [columns]="displayColumnsActive" model="fields-all" [param]="squareId" [hasNavigation]="true" [navigateTo]="navigateTo"></app-table>
  </div>

</div>
