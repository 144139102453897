<div fxLayout="column" fxFlex="100">
  <ng-container *ngIf="pending then showLoading else showMessage"></ng-container>
</div>

<ng-template #showLoading>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>


<ng-template #showMessage>
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center center">
    <label fxFlex="100" style="justify-content: flex-start; align-items: flex-start; font-size: 1rem;">Δημιουργία
      ειδοποίησης</label>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div fxLayout="row" fxFlex="100" style="margin-top: 1rem;">

    <div fxLayout="column">
      <h3>1. Τίτλος ειδοποίησης</h3>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="title">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxFlex="100" style="margin-top: 1rem;">

    <div fxLayout="column">
      <h3>2. Γράψτε το μήνυμά σας</h3>
      <quill-editor [styles]="editorStyle" [(ngModel)]="message" [textContent]="text"></quill-editor>
    </div>
  </div>


  <div fxLayout="row wrap" fxFlex="100" style="margin-top: 1rem;">
    <div fxLayout="column" fxFlex="49">
      <h3>3. Επιλέξτε τους χρήστες που θα αποσταλεί η ειδοποίηση</h3>
      <mat-form-field appearance="outline" style="overflow-x:hidden;">
        <mat-select [(ngModel)]="selectedUsers" multiple>
          <input [(ngModel)]="searchTxt" matInput placeholder="Αναζήτηση με επώνυμο" style="width: 80%;padding: 1rem;">
          <mat-option *ngFor="let user of users | search : searchTxt"
                      [value]="user">{{user.firstname}} {{user.lastname}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxFlex="49">
      <h3>4. Ημερομηνία αποστολής</h3>
      <mat-form-field appearance="outline">
        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Επιλέξτε ημερομηνία και ώρα" [(ngModel)]="sendDate" (selectionchange)="changeEvent()"
               [min]="minDate" [max]="maxDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false"
                                 [stepHour]="stepHour" [stepMinute]="stepMinute"
                                 [touchUi]="true" [enableMeridian]="false"
                                 [hideTime]="false">
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxFlex="100" fxLayoutAlign="flex-end">
    <button mat-raised-button [class.spinner]="isLoading" [disabled]="shouldBeDisabled() || isLoading" (click)="submit()">Δημιουργία</button>
  </div>
</ng-template>

