import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {CommonService} from '../../../services/common.service';
import {MatDialog} from '@angular/material/dialog';
import {ActionsDialogComponent} from '../shared/actions-dialog/actions-dialog.component';
import {ToastMessagesService} from '../../../services/toast-messages.service';

@Component({
  selector: 'app-squares',
  templateUrl: './squares.component.html',
  styleUrls: ['./squares.component.scss']
})
export class SquaresComponent implements OnInit {
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  @ViewChild('table', {static: true}) table: any;
  sectorId: number;
  sectorName: string;
  // displayColumns = [{key: 'name', label: 'Όνομα τετραγώνου'},  {key: 'is_active', label: 'Κατάσταση'},
  // {key: 'max_supply', label: 'Μέγιστη παροχή'}];
  displayColumns = [{key: 'name', label: 'Όνομα τετραγώνου'}, {key: 'is_active', label: 'Ανοιχτό'},
    {key: 'max_open_supply', label: 'Άρδευση'}, {key: 'open_supply', label: 'Παροχή'},
    {key: 'planned_liters', label: 'Προγραμματισμός'}];

  model = 'squares';
  hasNavigation = true;
  navigateTo = 'start/fields';
  status: number;
  startDate: string;
  sector_square_irrigation_id: number | null;

  constructor(public route: ActivatedRoute,
              public commonService: CommonService,
              public toastMessageService: ToastMessagesService,
              public dialog: MatDialog,
              public router: Router) {
    this.status = 0;
  }

  async ngOnInit() {
    try {
      const options = JSON.parse(localStorage.getItem('sectorOptions'));
      this.status = +options.isActive;
      this.startDate = options.start_date;
      this.sector_square_irrigation_id = +options?.sector_square_irrigation_id;
      this.route.params.subscribe((params) => {
        this.sectorId = +params.sector_id;
        this.sectorName = params.sector_name;
      });
    } catch (e) {
      this.toastMessageService.toastErrorMessage(e.message);
    }
  }

  async navigateToSquareFields(squareId: number, squareName: string) {
    await this.router.navigate([`start/fields/${squareId}/${squareName}`]);
  }

  exportToExcel(fileName: string) {
    this.table.exportToExcel(fileName);
  }

  isButtonDisabled() {
    return this.table.dataSource.filteredData.length === 0;
  }

  start() {
    this.dialog.open(ActionsDialogComponent, {
      disableClose: true,
      data: {
        mode: 1, // start
        id: this.sectorId,
        title: 'Έναρξη άρδευσης',
        name: this.sectorName,
        irrigationId: this.sector_square_irrigation_id,
        model: 'sectors'
      }
    });
  }
  edit() {
    this.dialog.open(ActionsDialogComponent, {
      disableClose: true,

      data: {
        mode: 2, // start
        id: this.sectorId,
        title: 'Επεξεργασία άρδευσης',
        name: this.sectorName,
        irrigationId: this.sector_square_irrigation_id,
        startDate: this.startDate,
        model: 'sectors'
      }
    });
  }
  stop() {
    this.dialog.open(ActionsDialogComponent, {
      disableClose: true,

      data: {
        mode: 0, // start
        id: this.sectorId,
        name: this.sectorName,
        title: 'Λήξη άρδευσης',
        irrigationId: this.sector_square_irrigation_id,
        model: 'sectors'
      }
    });
  }
}
