<div fxLayout="column" class="container">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxFlex="100">
      <h1>Ρυθμίσεις</h1>
    </div>
  </div>

  <mat-divider></mat-divider>

  <ng-container *ngIf="!pending">
    <div fxLayout="column" style="padding: 1rem;">
      <h2>Καθολική ενεργοποίηση/απενεργοποίηση ενεργειών</h2>
      <div fxLayout="row" fxLayoutGap="15px">
        <button mat-raised-button (click)="openDisabledDialog('is_disabled_crop')">
          Επεξεργασία καλλιέργειας
        </button>
        <button mat-raised-button (click)="openDisabledDialog('is_disabled_irrigation')">
          Επεξεργασία άρδευσης
        </button>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div fxLayout="row" fxFlex="100" style="padding: 1rem;">
      <div fxLayout="column">
        <h2>Επεξεργασία ακτίνας ενεργειών</h2>

        <div fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="start center center">
        <mat-form-field appearance="outline">
          <mat-label>
            Ακτίνα
          </mat-label>
          <input matInput [(ngModel)]="configuration.radius" type="number">
        </mat-form-field>

          <div>
          <button [class.spinner]="isLoading" [disabled]="isLoading" mat-raised-button (click)="updateRadius()">Ενημερώση ακτίνας</button>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>
    <div style="padding: 1rem;">
      <h2>Ενημέρωση δεδομένων</h2>

      <input #importFile type="file" style="display: none;" (change)="incomingFile($event)" name="registry" accept=".xlsx">

      <button *ngIf="!registryFile" mat-raised-button (click)="browseFiles('registry')">
        <mat-icon>file_upload</mat-icon>
        Ενημέρωση Μητρώου
      </button>

      <button *ngIf="registryFile" [disabled]="isLoading" mat-raised-button (click)="removeFile('registry')" style="margin-right: 20px;">
        <mat-icon>remove</mat-icon>
        Καθαρισμός Μητρώου
      </button>

      <button *ngIf="registryFile" [disabled]="isLoading" mat-raised-button (click)="uploadFile('registry','xlsx/person')">
        <mat-icon>file_upload</mat-icon>
        Μεταφόρτωση Μητρώου
      </button>

      <br><br>

      <input #importFile2 type="file" style="display: none;" (change)="incomingFile($event)" name="fields" accept=".xlsx">
      <button *ngIf="!fieldsFile" mat-raised-button (click)="browseFiles('fields')">
        <mat-icon>file_upload</mat-icon>
        Ενημέρωση Αγροτεμαχίων
      </button>

      <button *ngIf="fieldsFile" [disabled]="isLoading" mat-raised-button (click)="removeFile('fields')" style="margin-right: 20px;">
        <mat-icon>remove</mat-icon>
        Καθαρισμός Αγροτεμαχίων
      </button>

      <button *ngIf="fieldsFile" [disabled]="isLoading" mat-raised-button (click)="uploadFile('fields','xlsx/fields')">
        <mat-icon>file_upload</mat-icon>
        Μεταφόρτωση Αγροτεμαχίων
      </button>

    </div>

    <div fxLayout="row" style="align-items: center">
      <mat-icon style="padding-left: 1rem;margin-right: 10px;">info</mat-icon>
      <div>Για βοήθεια πατήστε <span style="text-decoration: underline;color: #0C54A0; cursor: pointer;" (click)="navigateToHelp()">εδώ</span></div>
    </div>



    <!--    <mat-divider></mat-divider>-->


<!--    <div fxLayout="row" fxFlex="100" style="padding: 1rem;">-->
<!--      <div fxLayout="column">-->
<!--        <h2>Ρυθμίσεις μετεωρολογικών σταθμών</h2>-->

<!--        <mat-slide-toggle [(ngModel)]="configuration.meteo_station_existence">Ύπαρξη μετεωρολογικών σταθμών</mat-slide-toggle>-->


<!--        <mat-form-field appearance="outline">-->
<!--          <mat-label>-->
<!--            Έκδοση server-->
<!--          </mat-label>-->
<!--          <textarea [disabled]="!configuration.meteo_station_existence" matInput [(ngModel)]="configuration.meteo_station_urls"></textarea>-->
<!--        </mat-form-field>-->
<!--      </div>-->
<!--    </div>-->
  </ng-container>

</div>
