<div fxLayout="column" fxFlex="100" style="width: 800px;">
  <ng-container *ngIf="pending then showLoading else showMessage"></ng-container>
</div>

<ng-template #showLoading>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>


<ng-template #showMessage>
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center center">
    <label fxFlex="100"
           style="justify-content: flex-start; align-items: flex-start; font-size: 1rem;">{{this.notification.title}}</label>
    <button *ngIf="canDelete" mat-icon-button (click)="delete()" style="color: red;">
      <mat-icon>delete</mat-icon>
    </button>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div fxLayout="row" fxFlex="100" style="margin-top: 2rem;">
    {{this.notification.text}}
  </div>

  <div *ngIf="canDelete" fxLayout="column">

    <div fxLayout="row" fxFlex="100" style="margin-top: 2rem;" fxLayoutGap="15px">
      <mat-form-field appearance="outline" fxFlex="49">
        <mat-label>Ημ. Δημιουργίας</mat-label>
        <input matInput readonly [(ngModel)]="notification.date_created">
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="49">
        <mat-label>Ημ. Αποστολής</mat-label>
        <input matInput readonly [(ngModel)]="notification.send_date">
      </mat-form-field>
    </div>

    <div fxLayout="column" fxFlex="100" style="margin-top: 2rem;">
      <mat-label>Παραλήπτες</mat-label>
      <mat-chip-list aria-label="Παραλήπτες" fxLayout="row wrap" style="margin-top: 2rem;">
        <mat-chip color="primary" *ngFor="let recipient of notification.recipients">{{recipient.name}}</mat-chip>
      </mat-chip-list>
    </div>

  </div>
</ng-template>

