import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {LoadingService} from '../../../services/loading.service';
import {UserService} from '../../../services/user/user.service';
import {ToastMessagesService} from '../../../services/toast-messages.service';

@Component({
  selector: 'app-disabled-configuration-dialog',
  templateUrl: './disabled-configuration-dialog.component.html',
  styleUrls: ['./disabled-configuration-dialog.component.scss']
})
export class DisabledConfigurationDialogComponent implements OnInit {

  public users: any[];
  public field: string;
  public selectAll: boolean;
  public title: string;

  constructor(public matDialogRef: MatDialogRef<DisabledConfigurationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public userService: UserService,
              public toastMessageService: ToastMessagesService,
              public loadingService: LoadingService,
              ) { }

  ngOnInit(): void {
    this.users = this.data.users;
    this.field = this.data.field;
    this.selectAll = false;
    this.title = this.data.field === 'is_disabled_crop' ? 'Απενεργοποίηση επεξεργασίας καλλιέργειας' : 'Απενεργοποίηση επεξεργασίας άρδευσης';
  }

  public selectAllUsers(): void {
    if (this.selectAll){
      this.users.forEach(user => user.is_disabled = 0);
    } else {
      this.users.forEach(user => user.is_disabled = 1);
    }
  }

  public async saveConfiguration(): Promise<void> {
    this.users.forEach(u => { u.is_disabled = u.is_disabled === true || u.is_disabled === 1 ? 1 : 0; });
    try{
      this.matDialogRef.close();
      this.loadingService.setLoading(true);
      await this.userService.updateUsersConfiguration({users: this.users, field: this.field});
      this.toastMessageService.toastMessages(this.field === 'is_disabled_crop' ? 'Η ενημέρωση της επεξεργασίας καλλιεργειών ολοκληρώθηκε'
       : 'Η ενημέρωση της επεξεργασίας άρδευσης ολοκληρώθηκε');
    } catch (e) {
      this.toastMessageService.toastErrorMessage(e.message);
    } finally {
      this.loadingService.setLoading(false);
    }
  }
}
