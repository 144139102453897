import { Component, OnInit } from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {LoginUser} from '../../models/LoginUser';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user/user.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  user: any;

  constructor(private userService: UserService, public router: Router) {
    if (!this.userService.validateUser()) {
      console.log('redirecting back to login');
      this.router.navigate(['login']);
    }
  }

  ngOnInit(): void {
  }

}
