<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
<div fxLayout="column" style="height: 100vh;" fxFlex="100">
<mat-toolbar>
  <mat-toolbar-row fxLayoutAlign="space-between center" style="margin-top: 10px;">
    <div fxHide.lt-md><a routerLinkActive="active" routerLink="/start/home">
      <img src="assets/general/logo.png" class="logo" alt="">
    </a></div>
    <div fxShow.lt-md fxHide.gt-sm>
      <p>
        <button mat-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </p>
    </div>
    <div style="margin-right: 25px" fxLayout="row" fxLayoutAlign="center center">
      <h3>{{user?.firstname}} {{user?.lastname}}</h3>
      <button matTooltip="Αποσύνδεση" mat-icon-button (click)="logout()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container class="example-container" fxFlex="100">
  <mat-sidenav [mode]="(screenWidth > 960) ? 'side' : 'over'"
               #sidenav [opened]="(screenWidth > 960)">
    <div fxLayout="column" fxLayoutAlign="start start center" fxLayoutGap="30px" style="margin-top: 10px;">
      <div fxLayout="row" class="menu-section">

        <div fxLayout="column" fxLayoutAlign="start start center" fxFlex="100">

          <button fxLayout="row" fxFlex="100" mat-button (click)="navigateTo('start/users')" style="align-content: center;">
              <mat-icon>perm_identity</mat-icon>
              &nbsp; <label>Χρήστες</label>
          </button>

          <button mat-button (click)="navigateTo('start/registry')">
            <mat-icon>people_outline</mat-icon>
            &nbsp; Μητρώο
          </button>

          <button mat-button (click)="navigateTo('start/sectors')">
            <mat-icon>
              <span class="material-symbols-outlined">sprinkler</span>
            </mat-icon>
            &nbsp; Άρδευση
          </button>

          <button mat-button (click)="navigateTo('start/crops')">
            <mat-icon>eco</mat-icon>
            &nbsp; Καλλιέργειες
          </button>

<!--          <button mat-button (click)="navigateTo('start/history')">-->
<!--            <mat-icon>history</mat-icon>-->
<!--            &nbsp; Ιστορικό-->
<!--          </button>-->

<!--          <button mat-button (click)="navigateTo('start/schedule')">-->
<!--            <mat-icon>calendar_today</mat-icon>-->
<!--            &nbsp; Προγραμματισμός-->
<!--          </button>-->

          <button mat-button (click)="navigateTo('start/settings')">
            <mat-icon>settings</mat-icon>
            &nbsp; Ρυθμίσεις
          </button>

          <button mat-button (click)="navigateTo('start/notifications')">
            <mat-icon>notifications</mat-icon>
            &nbsp; Ειδοποιήσεις
          </button>

          <button mat-button (click)="navigateTo('start/audits')">
            <mat-icon>text_snippet</mat-icon>
            &nbsp; Καταγραφές
          </button>

          <button mat-button (click)="navigateTo('start/logs')">
            <mat-icon>text_snippet</mat-icon>
            &nbsp; Ενέργειες
          </button>

        </div>



<!--        <div fxLayout="column" fxLayoutGap="22px">-->
<!--          <mat-icon>perm_identity</mat-icon>-->
<!--          <mat-icon>people_outline</mat-icon>-->
<!--          <mat-icon>-->
<!--            <span class="material-symbols-outlined">sprinkler</span>-->
<!--          </mat-icon>-->
<!--          <mat-icon>settings</mat-icon>-->
<!--          <mat-icon>notifications</mat-icon>-->
<!--          <mat-icon>text_snippet</mat-icon>-->
<!--        </div>-->
<!--        <div fxLayout="column" fxLayout fxLayoutGap="15px">-->
<!--          <button mat-button (click)="navigateTo('start/users')">Χρήστες</button>-->
<!--          <button mat-button (click)="navigateTo('start/registry')">Μητρώο</button>-->
<!--          <button mat-button (click)="navigateTo('start/sectors')">Άρδευση</button>-->
<!--          <button mat-button (click)="navigateTo('start/settings')">Ρυθμίσεις</button>-->
<!--          <button mat-button (click)="navigateTo('start/notifications')">Ειδοποιήσεις</button>-->
<!--          <button mat-button (click)="navigateTo('start/audits')">Καταγραφές</button>-->
<!--        </div>-->
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content  fxFlex="100">
    <div fxFlex="100">
    <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
</div>
