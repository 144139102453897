<div fxLayout="column" class="container">

  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxFlex="100">
      <h1>Ενέργειες αγροτεμαχίου {{field?.name}} - {{field?.community_name}}</h1>
    </div>

    <div fxLayoutAlign="end center center" fxLayoutGap="5px">
      <button (click)="edit()" mat-raised-button class="edit" matTooltip="Επεξεργασία" *ngIf="isReadOnly">
        Επεξεργασία <mat-icon>edit</mat-icon>
      </button>

      <button (click)="edit()" mat-raised-button class="edit" matTooltip="Επεξεργασία" *ngIf="!isReadOnly">
        Επεξεργασία <mat-icon>close</mat-icon>
      </button>
      <button  [disabled]="field?.acres <= 1" mat-raised-button class="stop" matTooltip="Διαχωρισμός" (click)="split()">
        Διαχωρισμός <mat-icon>call_split</mat-icon>
      </button>

      <button  [disabled]="field?.main_field_id === null" mat-raised-button class="start" matTooltip="Επανένωση" (click)="union()">
        Επανένωση <mat-icon>link</mat-icon>
      </button>
    </div>

  </div>
  <mat-divider></mat-divider>


  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" style="margin-top: 1rem;">
    <div fxFlex="100">
      <h1>Πληροφορίες αγροτεμαχίου {{field?.name}} - {{field?.community_name}}</h1>
    </div>

  </div>
  <mat-divider></mat-divider>

  <div *ngIf="!pending" fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="100" style="margin-top: 1rem;" fxLayoutGap="20px">
    <mat-form-field appearance="outline" fxFlex="25">
      <mat-label>Καλλιέργεια</mat-label>
      <input matInput [(ngModel)]="field.crop_name" disabled>
    </mat-form-field>
    <div *ngIf="!isReadOnly">
      <button mat-icon-button (click)="select('crop')">
        <mat-icon>edit</mat-icon>
      </button>
    </div>

    <mat-form-field appearance="outline" fxFlex="25">
      <mat-label>Ιδιοκτήτης</mat-label>
      <input matInput [(ngModel)]="field.owner_fullname" disabled>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="25">
      <mat-label>Έκταση</mat-label>
      <input matInput [(ngModel)]="field.acres" disabled>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="25">
      <mat-label>Ενοικιαστής</mat-label>
      <input matInput [(ngModel)]="field.renter_fullname" disabled>
    </mat-form-field>
    <div *ngIf="!isReadOnly">
      <button mat-icon-button (click)="select('renter')">
        <mat-icon>edit</mat-icon>
      </button>
    </div>

    <mat-form-field appearance="outline" fxFlex="25">
      <mat-label>Αρδευτής</mat-label>
      <input matInput [(ngModel)]="field.irrigator_fullname" disabled>
    </mat-form-field>
    <div *ngIf="!isReadOnly">
      <button mat-icon-button (click)="select('irrigator')">
        <mat-icon>edit</mat-icon>
      </button>
    </div>

    <div *ngIf="!isReadOnly">
      <button mat-raised-button (click)="promptSave()">
        Αποθήκευση αλλαγών
      </button>
    </div>

  </div>
</div>
