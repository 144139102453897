<div fxLayout="column" class="container">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxFlex="90">
      <h1>Επεξεργασία χρήστη</h1>
    </div>

    <div fxLayout="row" fxFlex="10" style="margin-right: 1rem;">
      <button class="app-button" mat-icon-button color="primary" (click)="changeUserStatus()"  matTooltip="Απενεργοποίηση χρήστη">
        <mat-icon *ngIf="isUserActive()">visibility</mat-icon>
        <mat-icon *ngIf="!isUserActive()">visibility_off</mat-icon>
      </button>

    <button class="app-button" mat-icon-button color="primary" (click)="deleteUser()"  matTooltip="Διαγραφή χρήστη">
      <mat-icon style="color: red;">delete</mat-icon>
    </button>

    <button class="app-button" mat-icon-button color="primary" (click)="editUser()" [disabled]="isLoading" matTooltip="Αποθήκευση">
     <mat-icon>done</mat-icon>
    </button>
    </div>
  </div>

  <div>
    <mat-divider></mat-divider>
  </div>

  <div fxLayout="column">
    <form [formGroup]="userForm">

      <div fxLayout="row wrap" fxLayoutAlign="start center" style="margin-top: 1rem;">

        <div fxLayout="column">
          <h3>Χρήστης στο μητρώο*</h3>
        <mat-form-field appearance="outline">
          <mat-label>Αναζήτηση με ΑΦΜ</mat-label>
          <input matInput placeholder="Αναζήτηση με ΑΦΜ" formControlName="vatNumber" readonly>
        </mat-form-field>
        </div>
        <div fxFlexAlign="flex-end center">
        <button mat-button (click)="searchPerson()" [disabled]="vatNumber === undefined">
          <mat-icon>search</mat-icon>
        </button>
        </div>

      </div>

      <div fxLayout="row" fxLayoutGap="15px">
        <mat-form-field appearance="outline">
          <mat-label>Όνομα χρήστη</mat-label>
          <input matInput placeholder="Όνομα χρήστη" formControlName="username">
          <!-- <mat-hint align="end">{{userForm.username.value.length}} / 10</mat-hint> -->
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Συνθηματικό</mat-label>
          <input matInput placeholder="Συνθηματικό" formControlName="hint" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Ρόλοι</mat-label>
          <mat-select multiple formControlName="userRoles" [compareWith]="compareFn">
            <mat-option *ngFor="let role of a" [value]="role">{{role.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="displaySectorSupervisorSelection()">
          <mat-label>Τομείς επόπτη</mat-label>
          <mat-select multiple formControlName="supervisorSectors">
            <mat-option *ngFor="let sector of availableSectors" [value]="sector">{{sector.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="displaySectorHydronomeaSelection()">
          <mat-label>Τομείς υδρονομέα</mat-label>
          <mat-select multiple formControlName="hydronomeasSectors">
            <mat-option *ngFor="let sector of availableSectors" [value]="sector">{{sector.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="displaySectorViewOnlySelection()">
          <mat-label>Τομείς επισκέπτη</mat-label>
          <mat-select multiple formControlName="viewonlySectors">
            <mat-option *ngFor="let sector of availableSectors" [value]="sector">{{sector.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutGap="10px">
        <div *ngIf="isDisabledCropVisible">
          <mat-slide-toggle color="primary" [disabled]="isLoading"
                            (change)="onChange($event, 'isDisabledCrop')"
                            formControlName="isDisabledCrop">Απενεργοποίηση επεξεργασίας καλλιέργειας</mat-slide-toggle>
        </div>
        <div *ngIf="isDisabledIrrigationVisible">
          <mat-slide-toggle color="primary" [disabled]="isLoading"
                           (change)="onChange($event, 'isDisabledIrrigation')"
                           formControlName="isDisabledIrrigation">Απενεργοποίηση επεξεργασίας άρδευσης</mat-slide-toggle>
        </div>
      </div>

    </form>
  </div>

<!--  <div fxLayout="row wrap" style="margin-left: 2%;">-->

<!--        <form fxLayout="column" [formGroup]="userForm">-->
<!--&lt;!&ndash;          <div fxLayout="row wrap" style="margin: 10px;">&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="margin-top: 20px;margin-right: 5px;">*Χρήστης στο μητρώο: </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;              <input matInput placeholder="Αναζήτηση με ΑΦΜ" formControlName="vatNumber">&ndash;&gt;-->
<!--&lt;!&ndash;            </mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;            <button mat-button (click)="searchPerson()" [disabled]="vatNumber === undefined">&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-icon>search</mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;            </button>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div fxLayout="row" *ngIf="person && person.length > 0">&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="margin-left: 10px;margin-right: 10px;margin-top: 8px;">Όνομα: {{person[0].firstname}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="margin-right: 10px;margin-left: 10px;margin-top: 8px;">Επώνυμο: {{person[0].lastname}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="margin-left: 5px;">&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-icon style="color: greenyellow;">done</mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div fxLayout="row wrap">&ndash;&gt;-->
<!--&lt;!&ndash;            <div fxLayout="column" style="margin: 10px;">&ndash;&gt;-->
<!--&lt;!&ndash;              <div fxLayout="row">&ndash;&gt;-->
<!--&lt;!&ndash;                <mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;                  <input matInput placeholder="*Όνομα χρήστη" formControlName="username">&ndash;&gt;-->
<!--&lt;!&ndash;                </mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div fxLayout="column" style="margin: 10px;">&ndash;&gt;-->
<!--&lt;!&ndash;              <div fxLayout="row">&ndash;&gt;-->
<!--&lt;!&ndash;                <mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;                  <input matInput placeholder="*Συνθηματικό" formControlName="password">&ndash;&gt;-->
<!--&lt;!&ndash;                </mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--          <div style="width: 20px;margin: 10px;">*Ρόλοι:</div>-->
<!--          <div fxLayout="row wrap" style="margin: 10px;" fxLayoutAlign="space-between">-->
<!--            <div fxLayout="row wrap" class="checkbox-area">-->
<!--              <mat-checkbox *ngFor="let role of availableRoles" (change)="getSelectedRoles($event, role.id)" style="margin: 5px;">-->
<!--                {{role.labelText}}-->
<!--              </mat-checkbox>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div fxLayout="row wrap" *ngFor="let selected of selectedRoles" class="sector-config" [ngStyle.lt-sm]="{'width': '70%'}">-->
<!--            <div fxLayout="column" fxLayoutAlign="center">-->
<!--              <label>Τομείς του {{selected.role_id === 3 ? 'Επόπτη' : 'Υδρονομέα'}}</label>-->
<!--              <div fxLayout="row" *ngFor="let sector of availableSectors">-->
<!--                <mat-checkbox (change)="setSectorsToRole($event, selected.role_id, sector.id)">-->
<!--                  {{sector.name}}-->
<!--                </mat-checkbox>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div fxLayout="row" style="margin: 10px;" fxLayoutAlign="center center center">-->
<!--            <button mat-raised-button (click)="editUser()">-->
<!--              Αποθήκευση αλλαγών-->
<!--            </button>-->
<!--          </div>-->
<!--        </form>-->

<!--  </div>-->
</div>
