import {Component, Inject, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {ToastMessagesService} from '../../../../services/toast-messages.service'
import {CommonService} from '../../../../services/common.service'
import {Location} from '@angular/common'
import * as moment from 'moment'

@Component({
  selector: 'app-actions-dialog',
  templateUrl: './actions-dialog.component.html',
  styleUrls: ['./actions-dialog.component.scss']
})
export class ActionsDialogComponent implements OnInit {
  title: string
  mode: number // 0: stop - 1: start - 2: edit
  message: string
  date: any
  startDate: any
  currentDate: any
  id: number
  name: string
  irrigationId: number | null
  isLoading: boolean
  model: string
  currentStartDate: any
  dateEnd: any
  waterSupply: number
  where: string
  startDateTime: any
  minDate: Date
  maxDate: any
  estEnd:Date
  
  // estEnd: Date

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public commonService: CommonService,
              public toastMessageService: ToastMessagesService,
              public location: Location,
              public dialogRef: MatDialogRef<ActionsDialogComponent>) {
    this.title = data.title
    this.mode = data.mode
    const verb = data.mode === 0 ? 'τη λήξη' : data.mode === 1 ? 'την έναρξη' : 'την επεξεργασία'
    this.message = `Συμπληρώστε τα στοιχεία για ${verb} της άρδευσης`
    this.currentDate = moment(new Date()).format('DD/MM/YYYY')
    this.currentStartDate = moment(new Date()).format('DD/MM/YYYY HH:mm')
    this.id = +data.id
    this.name = data.name
    this.irrigationId = data?.irrigationId
    this.isLoading = false
    this.model = data.model
    this.waterSupply = 0
    this.startDate = data.startDate
    this.where = this.model === 'sectors' ? 'τομέα' : 'τετραγώνου'
  }

  ngOnInit(): void {
    console.log(this.data)
    if (this.model === "sectors") {
      if(this.mode === 1){
        this.minDate = new Date
        this.maxDate = new Date(new Date().setHours(23,59,59,999))
      }
      else if(this.mode === 2 || this.mode === 0){
        const options = JSON.parse(localStorage.getItem('sectorOptions'))
        this.minDate = moment(options.start_date, "DD-MM-YYYY HH:mm").toDate()
        // if(this.minDate > new Date){
        //   this.maxDate = this.minDate
        //   this.minDate = new Date
        // }
        // else
          this.maxDate = new Date(new Date().setHours(23,59,59,999))
        // console.log(this.minDate > this.maxDate)
      }
        
      // this.startDateTime = new Date(this.startDate)
      // this.minDate = moment(this.startDate, "DD-MM-YYYY HH:mm").toDate()
      // const max = this.startDateTime.setDate(this.startDateTime.getDate() + 1)
      // this.maxDate = moment(max).toLocaleString()
      // this.maxDate = new Date()
    }
    else if(this.model === "squares"){
      if(this.mode === 1){
        const options = JSON.parse(localStorage.getItem('sectorOptions'))
        this.minDate = moment(options.start_date, "DD-MM-YYYY HH:mm").toDate()
        this.maxDate = new Date(new Date().setHours(23,59,59,999))
      }
      else if(this.mode === 2 || this.mode === 0){
        const options = JSON.parse(localStorage.getItem('squareOptions'))
        this.minDate = moment(options.start_date, "DD-MM-YYYY HH:mm").toDate()
        // if(this.minDate > new Date){
        //   this.maxDate = this.minDate
        //   this.minDate = new Date
        // }
        // else
        this.maxDate = new Date(new Date().setHours(23,59,59,999))
      }
    }
  }

  close() {
    this.dialogRef.close()
  }

  async start() {
    try {
      const object = {
        id: this.id,
        name: this.name,
        start_time: moment(this.date).format('YYYY-MM-DD HH:mm'),
        estimated_end_time: this.model === 'sectors' ? moment(this.dateEnd).format('YYYY-MM-DD HH:mm') : null,
        supply: this.waterSupply
      }
      this.isLoading = true
      await this.commonService.putRequest(`${this.model}/start`, object)
      this.toastMessageService.toastMessages(`Η έναρξη της άρδευσης του ${this.where} ${this.name} ολοκληρώθηκε με επιτυχία.`)
      await this.back()
      this.close()
    } catch (e) {
      this.toastMessageService.toastErrorMessage(e.message)
    } finally {
      this.isLoading = false
    }
  }

  async back() {
    await this.location.back()
  }


  async edit() {
    try {
    const object = {
      start_time: moment(this.date).format('YYYY-MM-DD HH:mm'),
      sector_square_irrigation_id: this.irrigationId,
      supply: this.waterSupply,
    }
      this.isLoading = true
      await this.commonService.putRequest(`${this.model}/changeSupply`, object)
      this.toastMessageService.toastMessages(`Η επεξεργασία της άρδευσης του ${this.where} ${this.name} ολοκληρώθηκε με επιτυχία.`)
      await this.back()

      this.close()
    } catch (e) {
      this.toastMessageService.toastErrorMessage(e.message)
    } finally {
      this.isLoading = false
    }
  }

  async stop() {
    try {
    const object = {
      end_time: moment(this.date).format('YYYY-MM-DD HH:mm'),
      sector_square_irrigation_id: this.irrigationId
    }
      this.isLoading = true
      await this.commonService.putRequest(`${this.model}/stop`, object)
      this.toastMessageService.toastMessages(`Η λήξη της άρδευσης του ${this.where} ${this.name} ολοκληρώθηκε με επιτυχία.`)
      await this.back()

      this.close()
    } catch (e) {
      this.toastMessageService.toastErrorMessage(e.message)
    } finally {
      this.isLoading = false
    }
  }

  isInValid() {
    if (this.model === 'squares') {
      return false
    }
    if (!this.date || !this.dateEnd) {
      return true
    }
    const startTime = this.date.getTime()
    const endTime = this.dateEnd.getTime()

    return endTime < startTime
  }

  checkTimes() {
    if (!this.date || !this.dateEnd) {
      return
    }
    const startTime = this.date.getTime()
    const endTime = this.dateEnd.getTime()

    if (endTime < startTime) {
      this.toastMessageService.toastMessages(`Η ώρα λήξης θα πρέπει να είναι μεγαλύτερη από την ώρα έναρξης`)
    }
  }
}
