import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FieldService} from '../../../services/fields/field.service';
import {Field} from '../../models/Field';
import {ActionsDialogComponent} from '../shared/actions-dialog/actions-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CommonService} from '../../../services/common.service';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss']
})
export class FieldsComponent implements OnInit {
  @ViewChild('table', {static: true}) table: any;
  @ViewChild('table2', {static: true}) table2: any;
  @ViewChild('table3', {static: true}) table3: any;
  @ViewChild('tabGroup', {static: true}) tabGroup: any;
  squareId: number;
  squareName: string;
  allSquares: Field[];
  model = 'fields';
  // tslint:disable-next-line:variable-name
  sector_square_irrigation_id: number | null;
  status: number;
  dialogModel = 'squares';
  navigateTo = 'start/field';
  sectorActive: number;

  displayColumnsActive = [
    {key: 'displayName', label: 'Όνομα'},
    {key: 'crop_name', label: 'Καλλιέργεια'},
    {key: 'hasActiveIrrigation', label: 'Κατάσταση'},
    {key: 'acres', label: 'Έκταση'},
    {key: 'irrigator_fullname', label: 'Αρδευτής'},
    {key: 'start_time', label: 'Ώρα έναρξης'},
    {key: 'estimated_duration', label: 'Εκτιμώμενη διάρκεια'},
    {key: 'estimated_end_time', label: 'Εκτιμώμενη ώρα λήξης'},
    {key: 'method_name', label: 'Τρόπος άρδευσης'}];

  displayColumnsInActive = [
    {key: 'displayName', label: 'Όνομα'},
    {key: 'crop_name', label: 'Καλλιέργεια'},
    {key: 'acres', label: 'Έκταση'},
    {key: 'irrigator_fullname', label: 'Αρδευτής'},
  ];

  displayColumnsInFertile = [
    {key: 'displayName', label: 'Όνομα'},
    {key: 'crop_name', label: 'Καλλιέργεια'},
    {key: 'acres', label: 'Έκταση'},
    {key: 'irrigator_fullname', label: 'Αρδευτής'}];
  tabHeight: number;

  constructor(public route: ActivatedRoute,
              public dialog: MatDialog,
              public commonService: CommonService,
              private fieldService: FieldService) {
    this.tabHeight = window.innerHeight - 235;
  }

  async ngOnInit() {
    const options = JSON.parse(localStorage.getItem('squareOptions'));
    const sectorOptions = JSON.parse(localStorage.getItem('sectorOptions'));
    this.status = +options.isActive;
    this.sectorActive = +sectorOptions.isActive;
    this.sector_square_irrigation_id = +options?.sector_square_irrigation_id;
    this.route.params.subscribe((params) => {
      this.squareId = +params.square_id;
      this.squareName = params.square_name;
    });
  }

  exportToExcel() {
    if (this.tabGroup.selectedIndex === 0) {
      this.table.exportToExcel('ActiveIrrigation');
    } else if (this.tabGroup.selectedIndex === 1) {
      this.table2.exportToExcel('Fields');
    } else if (this.tabGroup.selectedIndex === 2) {
      this.table3.exportToExcel('Fields');
    }
  }

  isButtonDisabled(table): boolean {
    if (table) {
      return table.dataSource.filteredData.length <= 0;
    } else {
      return true;
    }
  }

  start() {
    this.dialog.open(ActionsDialogComponent, {
      disableClose: true,
      data: {
        mode: 1, // start
        id: this.squareId,
        title: 'Έναρξη άρδευσης',
        name: this.squareName,
        irrigationId: this.sector_square_irrigation_id,
        model: 'squares'
      }
    });
  }

  edit() {
    this.dialog.open(ActionsDialogComponent, {
      disableClose: true,
      data: {
        mode: 2, // edit
        id: this.squareId,
        title: 'Επεξεργασία άρδευσης',
        name: this.squareName,
        irrigationId: this.sector_square_irrigation_id,
        model: 'squares'
      }
    });
  }

  stop() {
    this.dialog.open(ActionsDialogComponent, {
      disableClose: true,

      data: {
        mode: 0, // stop
        id: this.squareId,
        name: this.squareName,
        title: 'Λήξη άρδευσης',
        irrigationId: this.sector_square_irrigation_id,
        model: 'squares'
      }
    });
  }

  sectorActivity() {
    // todo: check if sector is active and if sector start date is < than now
    return !this.sectorActive;
  }
}
