import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PersonService} from '../../../../services/person/person.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SnackbarService} from '../../../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.scss']
})
export class PersonFormComponent implements OnInit {
  @Input() personId;
  @Input() mode;
  buttonText: string;
  // emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  // Validators.pattern(this.emailPattern)

  personInfo = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    midname: new FormControl(''),
    vatnumber: new FormControl('', [Validators.maxLength(9), Validators.minLength(9)]),
    email: new FormControl('', [Validators.email]),
    address: new FormControl(''),
    city: new FormControl(''),
    mobileNumber: new FormControl('', Validators.required),
    phoneNumber: new FormControl(''),
    comments: new FormControl('')
  });

  constructor(private personService: PersonService,
              public route: ActivatedRoute,
              private snackBarService: SnackbarService,
              public router: Router) {
  }

  async ngOnInit() {
    if (this.mode === 'edit') {
      this.buttonText = 'Αποθήκευση';
    } else if (this.mode === 'create') {
      this.buttonText = 'Προσθήκη στο μητρώο';
    }
    if (this.personId) {
      const selectedPerson = await this.personService.getPersonById(this.personId);
      this.personInfo.controls.firstname.setValue(selectedPerson.firstname);
      this.personInfo.controls.lastname.setValue(selectedPerson.lastname);
      this.personInfo.controls.midname.setValue(selectedPerson.midname);
      this.personInfo.controls.vatnumber.setValue(selectedPerson.vat_number);
      this.personInfo.controls.email.setValue(selectedPerson.email);
      this.personInfo.controls.mobileNumber.setValue(selectedPerson.mobile_number);
      this.personInfo.controls.phoneNumber.setValue(selectedPerson.phone_number);
      this.personInfo.controls.address.setValue(selectedPerson.address);
      this.personInfo.controls.city.setValue(selectedPerson.city);
      this.personInfo.controls.comments.setValue(selectedPerson.comments);
    }
  }

  async editPerson() {
    if (this.personInfo.invalid) {
      this.snackBarService.toastMessagesFailure('Πρέπει να συμπληρώσετε σωστά τα υποχρεωτικά πεδία');

    } else {
      const model = this.createModel();
      const result = await this.personService.updatePerson(this.personId, model);
      if (result === 'Update completed') {
        await this.router.navigate(['/start/registry']);
        this.snackBarService.toastMessagesSuccess('Επιτυχής ενημέρωση στοιχείων');
      } else {
        this.snackBarService.toastMessagesFailure('Προέκυψε σφάλμα');
      }
    }
  }

  async addPerson() {
    if (this.personInfo.valid) {
      const model = this.createModel();
      await this.personService.addPerson(model);
      this.snackBarService.toastMessagesSuccess('Επιτυχής δημιουργία ατόμου');
      await this.router.navigate(['start/registry']);
    } else {
      this.snackBarService.toastMessagesFailure('Πρέπει να συμπληρώσετε σωστά όλα τα υποχρεωτικά πεδία για να συνεχίσετε');
    }
  }

  async performAction() {
    if (this.mode === 'edit') {
      await this.editPerson();
    } else if (this.mode === 'create') {
      await this.addPerson();
    }
  }

  createModel() {
    return {
      firstname: this.personInfo.get('firstname').value,
      lastname: this.personInfo.get('lastname').value,
      midname: this.personInfo.get('midname').value,
      vat_number: this.personInfo.get('vatnumber').value,
      email: this.personInfo.get('email').value,
      address: this.personInfo.get('address').value,
      city: this.personInfo.get('city').value,
      mobile_number: this.personInfo.get('mobileNumber').value,
      phone_number: this.personInfo.get('phoneNumber').value,
      comments: this.personInfo.get('comments').value
    };
  }
}
