import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToastMessagesService {

  constructor(public snackBar: MatSnackBar) { }

  toastMessages(message: string, action?) {
    action = action ? action : '';
    this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: ['blue-snackbar']
    });
  }

  toastErrorMessage(message: string) {
    this.snackBar.open(`Κάτι πήγε στραβά: ${message}`, '', {
      duration: 5000,
      panelClass: ['warning-snackbar']
    });
  }
}
