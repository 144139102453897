import { Injectable } from '@angular/core';
import {CommonService} from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private commonService: CommonService) { }

  async fetchAll() {
    return await this.commonService.getRequest(`roles`);
  }
}
