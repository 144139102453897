<div fxLayout="column" class="container">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxFlex="100">
      <h1>Ειδοποιήσεις</h1>
    </div>
    <button class="app-button" mat-icon-button color="primary" (click)="createNotification()" matTooltip="Δημιουργία">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>

  <mat-tab-group mat-stretch-tabs>
    <mat-tab label="Οι ειδοποιήσεις μου">
  <div fxFlex="99">
    <app-table [columns]="displayColumns" [model]="model" (actionCallBack)="showMessage($event)" [hasPopUpAction]="true"></app-table>
  </div>
    </mat-tab>

    <mat-tab label="Προγραμματισμένες ειδοποιήσεις">
      <div fxFlex="99">
        <app-table [columns]="displayColumns" [model]="'pendingNotifications'" (actionCallBack)="showMessage($event, true)" [hasPopUpAction]="true"></app-table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
