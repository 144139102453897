import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NotificationsService} from '../../../../services/notifications.service';
import {ToastMessagesService} from '../../../../services/toast-messages.service';
import {LoadingService} from '../../../../services/loading.service';
import {ConfirmOptionDialogComponent} from "../../shared/confirm-option-dialog/confirm-option-dialog.component";

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {
  notificationId: number;
  notification: any;
  pending: boolean;
  canDelete: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<NotificationDialogComponent>,
              public toastMessagesService: ToastMessagesService,
              private notificationService: NotificationsService) {
    this.notificationId = +data.id;
    this.pending = true;
    this.canDelete = data.canDelete;
  }

  async ngOnInit(): Promise<void> {
    try {
      const notifications = await this.initializeNotification();
      this.notification = Array.isArray(notifications) ? notifications[0] : notifications;
      console.log(this.notification);
    } catch (e) {
      this.toastMessagesService.toastErrorMessage(e.message);
    } finally {
      this.pending = false;
    }
  }

  async initializeNotification() {
    return this.canDelete ? await this.notificationService.fetchPendingById(this.notificationId) : await this.notificationService.fetchById(this.notificationId);
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  delete() {
    const confirm = this.dialog.open(ConfirmOptionDialogComponent, {
      data: {
        title: 'Διαγραφή ειδοποίησης',
        message: 'Είστε σίγουροι ότι θέλετε να διαγράψετε την ειδοποίηση?',
        YesBtn: 'Διαγραφή τώρα',
        NoBtn: 'Όχι'
      }
    });

    confirm.afterClosed().subscribe(async response => {
      if (response) {
        try {
          await this.notificationService.deleteNotification(this.notification.id);
          this.toastMessagesService.toastMessages('Η διαγραφή της ειδοποίησης ολοκληρώθηκε με επιτυχία');
          this.close(true);
        } catch (e) {
          this.toastMessagesService.toastErrorMessage(e.message);
        }
      }
    });

  }
}
