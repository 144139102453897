import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {CommonService} from '../../../../services/common.service';
import {TableService} from '../../../../services/table.service';
import {LoadingService} from '../../../../services/loading.service';
import {Router} from '@angular/router';
import {ToastMessagesService} from '../../../../services/toast-messages.service';
import {FileService} from '../../../../services/file.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit {
  @Input() columns: any;
  @Input() model: any;
  @Input() param: any;
  @Input() hasNavigation: boolean;
  @Input() isNested: boolean;
  @Input() navigateTo: string;
  @Input() callBack: (args: any) => void;
  @Input() actionIcon: string;
  @Output() actionCallBack: EventEmitter<any> = new EventEmitter<any>();
  @Input() hasPopUpAction: boolean;
  tableHeight: number;
  dataSource: MatTableDataSource<any>;
  displayColumns: string[];
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;

  constructor(private commonService: CommonService,
              private loadingService: LoadingService,
              public fileService: FileService,
              public router: Router,
              public toastMessagesService: ToastMessagesService,
              private tableService: TableService) {
    this.dataSource = new MatTableDataSource<any>([]);
  }

  async ngOnInit(): Promise<void> {
    try {
      this.tableHeight = this.isNested ? window.innerHeight - 295 : window.innerHeight - 235;

      this.loadingService.setLoading(true);
      this.displayColumns = this.columns.map(c => c.key);
      await this.initDataSource();
    } catch (e) {
      this.toastMessagesService.toastErrorMessage(e.message);
    } finally {
      this.loadingService.setLoading(false);
    }
  }

  async initDataSource() {
    this.dataSource = new MatTableDataSource<any>(await this.tableService.fetchDataFromModel(this.model, this.param));
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.commonService.setPaginatorTexts(this.paginator);
  }

  search(target: any) {
    const value = target.value.toLowerCase().trim();
    this.dataSource.filter = value;
  }

  formatField(field: any, column: string) {
    if (field === 'null' || field === undefined) {
      return '-';
    } else {
      if (typeof field === 'string') {
        return field && (field.length > 0) ? field.trim() : '-';
      } else {
        return field;
      }
    }
  }

  getLabel(column: string) {
    return this.columns.find(c => c.key === column).label;
  }

  async navigate(row: any) {

    if (this.hasPopUpAction) {
      this.actionCallBack.emit(row.id);
    }

    if (this.hasNavigation && this.navigateTo) {
      let url = '';

      if (this.model.includes('fields')) {
        url = `${this.navigateTo}/${row.id}`;
      }

      if (this.model === 'sectors') {
        const sectorOptions = {
          isActive: row.is_active,
          start_date: row.start_date,
          sector_square_irrigation_id: row.sector_square_irrigation_id
        };
        localStorage.setItem('sectorOptions', JSON.stringify(sectorOptions));
        url = `${this.navigateTo}/${row.id}/${row.name}`;
      }
      if (this.model === 'squares') {
        const squareOptions = {
          isActive: row.is_active,
          start_date: row.start_date,
          sector_square_irrigation_id: row.sector_square_irrigation_id
        };
        localStorage.setItem('squareOptions', JSON.stringify(squareOptions));
        url = `${this.navigateTo}/${row.id}/${row.name}`;
      }
      await this.router.navigate([url]);
    }
  }

  edit(id: number) {
    const item = this.dataSource.data.find(i => i.id === id);
    if (item) {
      this.callBack(item);
    }
  }

  actionCallback() {
    this.actionCallBack.emit();
  }

  exportToExcel(fileName: string) {
    const {data, columns} = this.formatExcelModel(fileName);
    this.fileService.exportAsCustomExcelFile(data, fileName, columns);
  }

  formatExcelModel(fileName: string) {
    if (fileName === 'Users') {
     return this.fileService.formatUserExcelModel(this.dataSource.filteredData);
    } else if (fileName === 'Registry') {
      return this.fileService.formatRegistryExcelModel(this.dataSource.filteredData);
    } else if (fileName === 'Sectors') {
      return this.fileService.formatSectorExcelModel(this.dataSource.filteredData);
    } else if (fileName === 'Squares') {
      return this.fileService.formatSquaresExcelModel(this.dataSource.filteredData);
    } else if (fileName === 'ActiveIrrigation') {
      return this.fileService.formatActiveIrrigationExcelModel(this.dataSource.filteredData);
    } else if (fileName === 'Fields') {
      return this.fileService.formatFieldsIrrigationExcelModel(this.dataSource.filteredData);
    }
  }
}
