import { Component, OnInit } from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {ToastMessagesService} from '../../../services/toast-messages.service';
import {FieldService} from '../../../services/fields/field.service';
import {LoadingService} from '../../../services/loading.service';
import {__await} from 'tslib';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  helpData: any;
  tables: []

  constructor(  public commonService: CommonService,
                public toastMessageService: ToastMessagesService,
                public loadingService: LoadingService)  {
    this.helpData = [];
  }

  async ngOnInit() {
    await this.getHelpData();
  }

  async getHelpData() {
    try{
      this.helpData = await this.commonService.getRequest('xlsx/help');

      this.tables = this.helpData.map(e=> {return e})
    } catch (e) {
      this.toastMessageService.toastErrorMessage('');
    }
  }
}
