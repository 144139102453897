import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-edit-person',
  templateUrl: './edit-person.component.html',
  styleUrls: ['./edit-person.component.scss']
})
export class EditPersonComponent implements OnInit {
  paramsId: number;

  constructor(public route: ActivatedRoute,
              public router: Router) { }

  ngOnInit() {

    this.route.params.subscribe((params) => {
      this.paramsId = +params.id;
      console.log(this.paramsId);
    });
  }
}
