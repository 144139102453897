import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CommonService} from '../../../../services/common.service';

@Component({
  selector: 'app-crop-fields',
  templateUrl: './crop-fields.component.html',
  styleUrls: ['./crop-fields.component.scss']
})
export class CropFieldsComponent implements OnInit {
  @ViewChild('table', {static: true}) table: any;
  squareId: number;
  squareName: string;
  model = 'fields';
  // tslint:disable-next-line:variable-name
  sector_square_irrigation_id: number | null;
  status: number;
  navigateTo = 'start/crop-single-field';

  displayColumnsActive = [
    {key: 'displayName', label: 'Όνομα'},
    {key: 'crop_name', label: 'Καλλιέργεια'},
    {key: 'hasActiveIrrigation', label: 'Κατάσταση'},
    {key: 'acres', label: 'Έκταση'},
    {key: 'irrigator_fullname', label: 'Αρδευτής'},
    {key: 'start_time', label: 'Ώρα έναρξης'},
    {key: 'estimated_duration', label: 'Εκτιμώμενη διάρκεια'},
    {key: 'estimated_end_time', label: 'Εκτιμώμενη ώρα λήξης'},
    {key: 'method_name', label: 'Τρόπος άρδευσης'}];
  constructor(public route: ActivatedRoute,
              public commonService: CommonService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.squareId = +params.square_id;
      this.squareName = params.square_name;
    });
  }

  exportToExcel(fileName: string) {
    this.table.exportToExcel(fileName);
  }

  isButtonDisabled() {
    return this.table.dataSource.filteredData.length === 0;
  }

}
