<div fxLayout="column">
  <div fxLayout="row" style="margin-left: 2%;margin-top: 2%;">
    <h1>Επεξεργασία ατόμου</h1>
  </div>

  <div style="margin: 1%;">
    <mat-divider></mat-divider>
  </div>

  <div fxLayout="row wrap" style="margin-left: 2%;">

    <app-person-form *ngIf="paramsId" style="width: 100%;" [personId]="paramsId" [mode]="'edit'"></app-person-form>

  </div>
</div>
