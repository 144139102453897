import {Injectable} from '@angular/core';
import {CommonService} from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class FieldService {

  constructor(private commonService: CommonService) {
  }

  async getSquareFields(squareId: number) {
    return await this.commonService.getRequest(`field/all/${squareId}`);
  }

  async getActiveSquareFields(squareId: number) {
    return await this.commonService.getRequest(`field/irrigated/${squareId}?is_active=1`);
  }

  async getInActiveSquareFields(squareId: number) {
    return await this.commonService.getRequest(`field/irrigated/${squareId}?is_active=0`);
  }

  async getInFertileSquareFields(squareId: number) {
    return await this.commonService.getRequest(`field/infertile/${squareId}`);
  }

  async fetchFieldById(fieldId: number) {
    return await this.commonService.getRequest(`field/web/${fieldId}`);
  }

  async fetchFieldsCrops() {
    return await this.commonService.getRequest(`crop`);
  }

  async updateField(object: any) {
    await this.commonService.putRequest(`field`, object);
  }

  async fieldUnion(object: any) {
    await this.commonService.putRequest(`field/union`, object);
  }

  async fieldSplit(object: any) {
    await this.commonService.putRequest(`field/split`, object);
  }

  async irrigationMethods() {
    return await this.commonService.getRequest(`irrigation/methods`);
  }

  async causeOfPause() {
    return await this.commonService.getRequest(`causeOfPause`);
  }

  async startField(object) {
    await this.commonService.postRequest(`irrigation/start`, object);
  }

  async pauseField(object) {
    await this.commonService.putRequest(`irrigation/pause`, object);
  }

  async stopField(object) {
    await this.commonService.putRequest(`irrigation/stop`, object);
  }

  async restartField(object) {
    await this.commonService.putRequest(`irrigation/restart`, object);
  }
}
