import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ToastMessagesService} from "../../../../services/toast-messages.service";
import {UserService} from "../../../../services/user/user.service";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  isOpen: boolean;
  screenWidth: number;
  user: any;
  constructor(private router: Router,
              private userService: UserService,
              public toastMessageService: ToastMessagesService) {
    this.isOpen = true;
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
  }

  async ngOnInit(): Promise<void> {
    try {
      this.user = await this.userService.getUserByToken();
    } catch (e) {
      this.toastMessageService.toastErrorMessage(e.message);
    }
  }

  async logout() {
    try {
      localStorage.removeItem('token');
      await this.router.navigate(['login']);
    } catch (e) {
      console.log(e);
    }
  }

  async navigateTo(route: string) {
    await this.router.navigate([route]);
  }

  async navigateToUsers() {
    await this.router.navigate(['start/users']);
  }

  async navigateToRegistry() {
    await this.router.navigate(['start/registry']);
  }

  async navigateToSectors() {
    await this.router.navigate(['start/sectors']);
  }

}
