<div fxLayout="column" class="container">

  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxFlex="100">
      <h1>Ενέργειες τετραγώνου {{squareName}}</h1>
    </div>

    <div   fxLayoutAlign="end center center" fxLayoutGap="5px">
      <button (click)="start()" [disabled]="commonService.isStartDisabled(status) || sectorActivity()" mat-raised-button class="start" matTooltip="Έναρξη">
        Έναρξη <mat-icon>play_circle_outline</mat-icon>
      </button>
      <button (click)="edit()" [disabled]="commonService.isEditDisabled(status)" mat-raised-button class="edit" matTooltip="Επεξεργασία">
        Επεξεργασία <mat-icon>edit</mat-icon>
      </button>
      <button (click)="stop()" [disabled]="commonService.isStopDisabled(status)" mat-raised-button class="stop" matTooltip="Λήξη">
        Λήξη <mat-icon>stop</mat-icon>
      </button>
    </div>

  </div>
  <mat-divider></mat-divider>


  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" style="margin-top: 1rem;">
    <div fxFlex="95">
      <h1>Αγροτεμάχια τετραγώνου {{squareName}}</h1>
    </div>

  </div>
  <mat-divider></mat-divider>

  <mat-tab-group #tabGroup mat-stretch-tabs [style.height.px]="tabHeight">
    <mat-tab label="Ενεργές αρδεύσεις">
      <div class="export-div">
        <button [disabled]="isButtonDisabled(table)" class="app-button" mat-icon-button color="primary" (click)="exportToExcel()" matTooltip="Εξαγωγή σε excel">
          <mat-icon>file_download</mat-icon>
        </button>
      </div>
      <div fxFlex="99">
      <app-table #table [columns]="displayColumnsActive" model="fields-active" [param]="squareId" [hasNavigation]="true" [navigateTo]="navigateTo" [isNested]="true"></app-table>
      </div>
    </mat-tab>
    <mat-tab label="Προβολή κύκλου">
      <div class="export-div">
        <button [disabled]="isButtonDisabled(table2)" class="app-button" mat-icon-button color="primary" (click)="exportToExcel()" matTooltip="Εξαγωγή σε excel">
          <mat-icon>file_download</mat-icon>
        </button>
      </div>
      <div fxFlex="99">
      <app-table #table2 [columns]="displayColumnsInActive" model="fields-inActive" [param]="squareId" [hasNavigation]="true" [navigateTo]="navigateTo" [isNested]="true"></app-table>
      </div>
    </mat-tab>
    <mat-tab label="Μη αρδευόμενα">
      <div class="export-div">
        <button [disabled]="isButtonDisabled(table3)" class="app-button" mat-icon-button color="primary" (click)="exportToExcel()" matTooltip="Εξαγωγή σε excel">
          <mat-icon>file_download</mat-icon>
        </button>
      </div>
      <div fxFlex="99">
      <app-table #table3 [columns]="displayColumnsInFertile" model="fields-inFertile" [param]="squareId" [hasNavigation]="true" [navigateTo]="navigateTo" [isNested]="true"></app-table>
      </div>
    </mat-tab>
  </mat-tab-group>



</div>
