import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {CommonService} from '../../../../services/common.service';
import {ToastMessagesService} from '../../../../services/toast-messages.service';
import {FieldService} from '../../../../services/fields/field.service';
import {LoadingService} from '../../../../services/loading.service';
import {UnionSplitDialogComponent} from '../union-split-dialog/union-split-dialog.component';
import {PersonService} from '../../../../services/person/person.service';
import {SelectionTableComponent} from '../../shared/selection-table/selection-table.component';
import {ConfirmOptionDialogComponent} from '../../shared/confirm-option-dialog/confirm-option-dialog.component';

@Component({
  selector: 'app-crop-single-field',
  templateUrl: './crop-single-field.component.html',
  styleUrls: ['./crop-single-field.component.scss']
})
export class CropSingleFieldComponent implements OnInit {
  fieldId: number;
  field: any;
  status: number;
  pending: boolean;
  isReadOnly: boolean;
  searchTxt: any;
  people: any;
  selectedRenter: any;
  selectedIrrigator: any;
  crops: any;
  peopleColumns = [{key: 'selection', label: ''}, {key: 'firstname', label: 'Όνομα'}, {
    key: 'lastname',
    label: 'Επώνυμο'
  }, {
    key: 'midname',
    label: 'Πατρώνυμο'
  }];
  cropColumns = [{key: 'selection', label: ''}, {key: 'name', label: 'Καλλιέργεια'}];

  constructor(public route: ActivatedRoute,
              private personService: PersonService,
              public dialog: MatDialog,
              public commonService: CommonService,
              public toastMessageService: ToastMessagesService,
              private fieldService: FieldService,
              public loadingService: LoadingService) {
    this.pending = true;
    this.status = 0;
    this.isReadOnly = true;
    this.people = [];
    this.crops = [];
  }

  async ngOnInit(): Promise<void> {
    try {
      this.loadingService.setLoading(true);
      this.fieldId = await this.subscribeToRouterParams();
      this.field = await this.initField();
    } catch (e) {
      this.toastMessageService.toastErrorMessage(e.message);
    } finally {
      this.loadingService.setLoading(false);
      this.pending = false;
    }
  }


  async subscribeToRouterParams(): Promise<number> {
    return new Promise((resolve) => {
      this.route.params.subscribe(async (params) => {
        resolve(+params.id);
      });
    });
  }


  async initField() {
    const field = await this.fieldService.fetchFieldById(this.fieldId);
    return field;
  }


  edit() {
    this.isReadOnly = !this.isReadOnly;
  }

  split() {
    const split = this.dialog.open(UnionSplitDialogComponent, {
      disableClose: true,
      data: {
        title: 'Διαχωρισμός',
        message: 'Συμπληρώστε τα στοιχεία για το διαχωρισμό',
        mode: 0,
        field: this.field
      }
    });

    split.afterClosed().subscribe(async response => {
      if (response) {
        await this.commonService.back();
      }
    });
  }

  union() {
    const save = this.dialog.open(ConfirmOptionDialogComponent, {
      data: {
        title: 'Επανένωση αγροτεμαχίου',
        message: 'Είστε σίγουροι ότι θέλετε να επανενώσετε το αγροτεμάχιο?',
        YesBtn: 'Ναι',
        NoBtn: 'Όχι τώρα'
      }
    });

    save.afterClosed().subscribe(async response => {
      if (response) {
        try {
          this.loadingService.setLoading(true);
          await this.fieldService.fieldUnion({id: this.fieldId});
          this.toastMessageService.toastMessages(`Η επανένωση ολοκληρώθηκε με επιτυχία`);
          await this.commonService.back();
        } catch (e) {
          this.toastMessageService.toastErrorMessage(e.message);
        } finally {
          this.loadingService.setLoading(false);
        }
      }
    });
  }


  select(field: string) {
    switch (field) {
      case 'crop':
        this.selectionTable('crop', this.crops, this.cropColumns, 'Επιλέξτε καλλιέργεια');
        break;
      case 'renter':
        this.selectionTable('renter', this.people, this.peopleColumns, 'Επιλέξτε ενοικιαστή');
        break;
      case 'irrigator':
        this.selectionTable('irrigator', this.people, this.peopleColumns, 'Επιλέξτε αρδευτή');
        break;
    }
  }

  selectionTable(model: string, dataSource: any, columns: any[], title: string) {
    const selection = this.dialog.open(SelectionTableComponent, {
      panelClass: 'myapp-no-padding-dialog',
      data: {
        dataSource,
        columns,
        model,
        title
      }
    });

    selection.afterClosed().subscribe(response => {
      if (response) {
        switch (model) {
          case 'crop':
            this.field.crop_id = response.id;
            this.field.crop_name = response.name;
            break;
          case 'renter':
            this.field.renter_id = response.id;
            this.field.renter_fullname = `${response.firstname} ${response.lastname} ${response.midname}`;
            break;
          case 'irrigator':
            this.field.irrigator_id = response.id;
            this.field.irrigator_fullname = `${response.firstname} ${response.lastname} ${response.midname}`;
            break;
        }
        console.log(response);
      }
    });
  }

  promptSave() {
    const save = this.dialog.open(ConfirmOptionDialogComponent, {
      data: {
        title: 'Ενημέρωση αγροτεμαχίου',
        message: 'Είστε σίγουροι ότι θέλετε να ενημερώσετε τις πληροφορίες του αγροτεμαχίου',
        YesBtn: 'Ενημέρωση',
        NoBtn: 'Όχι τώρα'
      }
    });

    save.afterClosed().subscribe(async response => {
      if (response) {
        try {
          this.loadingService.setLoading(true);
          const object = {
            id: this.fieldId,
            crop_id: this.field.crop_id,
            owner_id: this.field.owner_id,
            renter_id: this.field.renter_id,
            irrigator_id: this.field.irrigator_id
          };
          await this.fieldService.updateField(object);
          this.toastMessageService.toastMessages(`Η ενημέρωση ολοκληρώθηκε με επιτυχία`);
        } catch (e) {
          this.toastMessageService.toastErrorMessage(e.message);
        } finally {
          this.loadingService.setLoading(false);
        }
      }
    });
  }
}
