<div fxLayout="column" style="width: 450px;overflow-y: auto;"
     [ngStyle.lt-sm]="{'width':'200px', 'height':'99vh'}">
  <div fxLayout="row wrap" style="margin: 10px;" fxLayoutAlign="center">
    <div style="margin-top: 20px;margin-right: 5px;">*Χρήστης στο μητρώο: </div>
    <mat-form-field>
    <input matInput placeholder="Αναζήτηση με ΑΦΜ" [(ngModel)]="vatNumber">
    </mat-form-field>
    <button mat-button (click)="searchPerson()" [disabled]="vatNumber === undefined">
    <mat-icon>search</mat-icon>
      </button>
  </div>
  <div fxLayout="row" *ngIf="person && person.length > 0" fxLayoutAlign="center">
    <div style="margin-left: 10px;margin-right: 10px;margin-top: 8px;">Όνομα: {{person[0].firstname}}</div>
    <div style="margin-right: 10px;margin-left: 10px;margin-top: 8px;">Επώνυμο: {{person[0].lastname}}</div>
    <div style="margin-left: 5px;">
      <mat-icon style="color: greenyellow;">done</mat-icon>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center">
    <div fxLayout="column" style="margin: 10px;">
      <div fxLayout="row">
        <mat-form-field>
        <input matInput placeholder="*Όνομα χρήστη" [(ngModel)]="username">
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="column" style="margin: 10px;">
      <div fxLayout="row">
        <mat-form-field>
          <input matInput placeholder="*Συνθηματικό" [(ngModel)]="password">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div style="width: 20px;">*Ρόλοι:</div>
  <div fxLayout="row wrap" style="margin: 10px;" fxLayoutAlign="space-between">
    <div fxLayout="row wrap" class="checkbox-area">
      <mat-checkbox *ngFor="let role of availableRoles" (change)="getSelectedRoles($event, role.id)" style="margin: 5px;">
        {{role.labelText}}
      </mat-checkbox>
      </div>
    <div fxLayout="row wrap" *ngFor="let selected of selectedRoles" class="sector-config" [ngStyle.lt-sm]="{'width': '70%'}">
      <div fxLayout="column" fxLayoutAlign="center">
        <label>Τομείς του {{selected.role_id === 3 ? 'Επόπτη' : 'Υδρονομέα'}}</label>
        <div fxLayout="row wrap" *ngFor="let sector of availableSectors">
          <mat-checkbox (change)="setSectorsToRole($event, selected.role_id, sector.id)">
            {{sector.name}}
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" style="margin: 10px;" fxLayoutAlign="center center center">
    <button mat-raised-button (click)="addNewUser()">
      Δημιουργία νέου χρήστη
    </button>
  </div>
</div>
