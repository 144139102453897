<form [formGroup]="personInfo" style="width: 100%;" fxLayout="row">

  <div fxLayout="column" fxFlex="50">
    <mat-form-field>
      <label>*Όνομα: </label> <input matInput formControlName="firstname">
    </mat-form-field>

    <mat-form-field>
      <label>*Επώνυμο: </label> <input matInput formControlName="lastname">
    </mat-form-field>

    <mat-form-field>
      <label>ΑΦΜ: </label> <input matInput formControlName="vatnumber" type="number" oninput="this.value=this.value.slice(0,this.maxLength)" maxlength="9">
    </mat-form-field>

    <mat-form-field>
      <label>*Κινητό: </label> <input matInput formControlName="mobileNumber" type="number" oninput="this.value=this.value.slice(0,this.maxLength)" maxlength="10">
    </mat-form-field>

    <mat-form-field>
      <label>Διεύθυνση: </label> <input matInput formControlName="address">
    </mat-form-field>

  </div>
  <div fxLayout="column" fxFlex="50">

    <mat-form-field>
      <label>Μεσαίο όνομα: </label> <input matInput formControlName="midname">
    </mat-form-field>

    <mat-form-field>
      <label>email: </label> <input matInput formControlName="email">
    </mat-form-field>

    <mat-form-field>
      <label>Σταθερό: </label> <input matInput formControlName="phoneNumber" type="number" oninput="this.value=this.value.slice(0,this.maxLength)" maxlength="10">
    </mat-form-field>

    <mat-form-field>
      <label>Πόλη: </label> <input matInput formControlName="city">
    </mat-form-field>

    <mat-form-field>
      <label>Σχόλια: </label> <input matInput formControlName="comments">
    </mat-form-field>

  </div>

</form>
<div fxLayout="row" fxLayoutAlign="center" style="margin: 1%;">
  <button mat-raised-button (click)="performAction()">
    {{this.buttonText}}
  </button>
</div>
