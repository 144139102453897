import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ToastMessagesService} from '../../../../services/toast-messages.service';

@Component({
  selector: 'app-crop-squares',
  templateUrl: './crop-squares.component.html',
  styleUrls: ['./crop-squares.component.scss']
})
export class CropSquaresComponent implements OnInit {
  @ViewChild('table', {static: true}) table: any;
  sectorId: number;
  sectorName: string;
  displayColumns = [{key: 'name', label: 'Όνομα τετραγώνου'}, {key: 'is_active', label: 'Ανοιχτό'},
    {key: 'max_open_supply', label: 'Άρδευση'}, {key: 'open_supply', label: 'Παροχή'},
    {key: 'planned_liters', label: 'Προγραμματισμός'}];
  model = 'squares';
  hasNavigation = true;
  navigateTo = 'start/crop-fields';
  status: number;
  startDate: string;
  // tslint:disable-next-line:variable-name
  sector_square_irrigation_id: number | null;
  constructor(public route: ActivatedRoute,
              public toastMessageService: ToastMessagesService) { }

  ngOnInit(): void {
    try {
      const options = JSON.parse(localStorage.getItem('sectorOptions'));
      this.status = +options.isActive;
      this.sector_square_irrigation_id = +options?.sector_square_irrigation_id;
      this.route.params.subscribe((params) => {
        this.sectorId = +params.sector_id;
        this.sectorName = params.sector_name;
      });
    } catch (e) {
      this.toastMessageService.toastErrorMessage(e.message);
    }
  }

  exportToExcel(fileName: string) {
    this.table.exportToExcel(fileName);
  }

  isButtonDisabled() {
    return this.table.dataSource.filteredData.length === 0;
  }

}
