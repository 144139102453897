import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loadingObservable: BehaviorSubject<boolean>;
  textObservable: BehaviorSubject<string>;

  constructor() {
    this.loadingObservable = new BehaviorSubject<boolean>(false);
    this.textObservable = new BehaviorSubject<string>(undefined);
  }

  setLoading(status: boolean, text?: string) {
    this.loadingObservable.next(status);
    if (text) {
      this.setText(text);
    } else {
      this.setText(undefined);
    }
  }

  setText(text: string) {
    this.textObservable.next(text);
  }

  observe() {
    return this.loadingObservable.asObservable();
  }

  observeText() {
    return this.textObservable.asObservable();
  }
}
