<div fxLayout="column" class="container">

  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxFlex="100">
      <h1>Ενέργειες αγροτεμαχίου {{field?.name}} - {{field?.community_name}}</h1>
    </div>

    <div fxLayoutAlign="end center center" fxLayoutGap="5px">
      <button (click)="finalize()" [disabled]="!field || field.is_position_confirmed_by_hydronomea === 1 || [!status]"
              mat-raised-button class="start" matTooltip="Οριστικοποίηση">
        Οριστικοποίηση
        <mat-icon>recommend</mat-icon>
      </button>

      <button (click)="approve()" [disabled]="isApprovalDisabled(field)" mat-raised-button class="start"
              matTooltip="Έγκριση">
        Έγκριση
        <mat-icon>check_circle</mat-icon>
      </button>

      <button (click)="start()" [disabled]="isStartDisabled() || [!status]" mat-raised-button class="start" matTooltip="Έναρξη">
        Έναρξη
        <mat-icon>play_circle_outline</mat-icon>
      </button>

      <button (click)="pause()" [disabled]="isPausedDisabled()" mat-raised-button class="edit" matTooltip="Επεξεργασία">
        Παύση
        <mat-icon>pause</mat-icon>
      </button>

      <button (click)="restart()" [disabled]="isRestartDisabled()" mat-raised-button class="edit" matTooltip="Επανέρξη">
        Επανέναρξη
        <mat-icon>start</mat-icon>
      </button>

      <button (click)="stop()" [disabled]="isStopDisabled()" mat-raised-button class="stop" matTooltip="Λήξη">
        Λήξη
        <mat-icon>stop</mat-icon>
      </button>
    </div>

  </div>
  <mat-divider></mat-divider>


  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" style="margin-top: 1rem;">
    <div fxFlex="100">
      <h1>Πληροφορίες αγροτεμαχίου {{field?.name}} - {{field?.community_name}}</h1>
    </div>

  </div>
  <mat-divider></mat-divider>

  <div *ngIf="!pending" fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="100" style="margin-top: 1rem;"
       fxLayoutGap="20px">
    <mat-form-field appearance="outline" fxFlex="25">
      <mat-label>Καλλιέργεια</mat-label>
      <input matInput [(ngModel)]="field.crop_name" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="25">
      <mat-label>Ιδιοκτήτης</mat-label>
      <input matInput [(ngModel)]="field.owner_fullname" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="25">
      <mat-label>Έκταση</mat-label>
      <input matInput [(ngModel)]="field.acres" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="25">
      <mat-label>Ενοικιαστής</mat-label>
      <input matInput [(ngModel)]="field.renter_fullname" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="25">
      <mat-label>Αρδευτής</mat-label>
      <input matInput [(ngModel)]="field.irrigator_fullname" readonly>
    </mat-form-field>
  </div>
</div>
