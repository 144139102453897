import {Component, OnInit} from '@angular/core';
import {AuditsService} from '../../../services/audits/audits.service';
import {ToastMessagesService} from "../../../services/toast-messages.service";
import * as moment from "moment";

@Component({
  selector: 'app-audits',
  templateUrl: './audits.component.html',
  styleUrls: ['./audits.component.scss']
})
export class AuditsComponent implements OnInit {
  data = [];
  selectedCategory: any;
  chartOptions = {
    xLabel: '',
    yLabel: '',
    legendTitle: ''
  };

  categories = [{
    id: 1,
    label: 'Αρδευόμενα αγροτεμάχια στον τομέα',
    value: 'irrigated_by_sector'
  },
    {
      id: 2,
      label: 'Βαθμός απόδοσης δικτύου',
      value: 'network_performance'
    },
    {
      id: 3,
      label: 'Στοιχεία απόδοσης τομέων',
      value: 'sector_performance'
    },
    {
      id: 4,
      label: 'Χρήση νερού ανά καλλιέργεια και στρέμμα',
      value: 'water_per_crop_per_acres'
    }];
  fromDate: any;
  toDate: any;

  constructor(private auditService: AuditsService, public toastMessagesService: ToastMessagesService) {
  }

  async ngOnInit(): Promise<void> {
    try {
      // this.data = await this.initializeStatistics();
      // console.log(this.data);
    } catch (e) {
      console.log(e);
    }
  }

  async initializeStatistics() {
    const result = [];
    this.data = [];
    let url = '';
    if(this.selectedCategory.id !== 3) {
      url = this.selectedCategory.value;
    } else {
      const filterFrom = moment(this.fromDate).format('YYYY-MM-DD');
      const filterTo = moment(this.toDate).format('YYYY-MM-DD');
      url = `${this.selectedCategory.value}&from=${filterFrom}&to=${filterTo}`;
    }

    const data = await this.auditService.fetchAll(url);
    data.forEach(record => {
      const model = {
        name: record.name,
        series: []
      };
      record.series.forEach(s => {
        model.series.push({name: s.name, value: s.value});
      });
      result.push(model);
    });

    this.data = result;
    // return result;
  }

  async categoryChanged() {

  }

  async search() {
    try {
      switch (this.selectedCategory.id) {
        case 1:
          this.chartOptions.xLabel = 'Πλήθος';
          this.chartOptions.yLabel = 'Τομείς';
          this.chartOptions.legendTitle = 'Κατάσταση';
          break;
        case 2:
          this.chartOptions.xLabel = 'Απώλεια δικτύου (λίτρα)';
          this.chartOptions.yLabel = 'Τομείς';
          this.chartOptions.legendTitle = 'Κατάσταση';
          break;
        case 3:
          this.chartOptions.xLabel = 'Βαθμός χρήσης νερού';
          this.chartOptions.yLabel = 'Τομείς';
          this.chartOptions.legendTitle = 'Κατάσταση';
          break;
        case 4:
          this.chartOptions.xLabel = 'Χρήση νερού ανά στρέμμα';
          this.chartOptions.yLabel = 'Καλλιέργεια';
          this.chartOptions.legendTitle = 'Κατάσταση';
          break;
      }

      await this.initializeStatistics();
    } catch (e) {
      this.toastMessagesService.toastErrorMessage(e.message);
    }
  }
}
