<div fxLayout="column" class="container">

  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxFlex="100">
      <h1>Ενέργειες τομέα {{sectorName}}</h1>
    </div>

    <div   fxLayoutAlign="end center center" fxLayoutGap="5px">
      <button (click)="start()" [disabled]="commonService.isStartDisabled(status)" mat-raised-button class="start" matTooltip="Έναρξη">
        Έναρξη <mat-icon>play_circle_outline</mat-icon>
      </button>
      <button (click)="edit()" [disabled]="commonService.isEditDisabled(status)" mat-raised-button class="edit" matTooltip="Επεξεργασία">
        Επεξεργασία <mat-icon>edit</mat-icon>
      </button>
      <button (click)="stop()" [disabled]="commonService.isStopDisabled(status)" mat-raised-button class="stop" matTooltip="Λήξη">
        Λήξη <mat-icon>stop</mat-icon>
      </button>
    </div>

  </div>

  <mat-divider></mat-divider>


  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" style="margin-top: 1rem;">
    <div fxFlex="95">
      <h1>Τετράγωνα τομέα {{sectorName}}</h1>
    </div>

    <button [disabled]="isButtonDisabled()" class="app-button" mat-icon-button color="primary" (click)="exportToExcel('Squares')" matTooltip="Εξαγωγή σε excel">
      <mat-icon>file_download</mat-icon>
    </button>

  </div>
  <mat-divider></mat-divider>

  <div fxFlex="100">
    <app-table #table [columns]="displayColumns" [param]="sectorId" [model]="model" [hasNavigation]="hasNavigation" [navigateTo]="navigateTo"></app-table>
  </div>
</div>
