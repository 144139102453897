import { Injectable } from '@angular/core';
import {CommonService} from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class SquareService {

  constructor(private commonService: CommonService) { }

  async getSectorSquares(sectorId: number) {
    return await this.commonService.getRequest(`squares/${sectorId}`);
  }
}
