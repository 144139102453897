<div fxLayout="column" fxFlex="100" style="width: 800px;">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center center">
    <label fxFlex="100"
           style="justify-content: flex-start; align-items: flex-start; font-size: 1rem;">{{this.title}}</label>

    <button mat-icon-button (click)="submit()" [style.color]="isDisabled() ? 'gray' : 'green'"
            [disabled]="isDisabled()">
      <mat-icon>done</mat-icon>
    </button>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <!--  pause-->
  <div *ngIf="mode === 'pause'" fxFlex="100">
    <div fxLayout="row" fxFlex="100" style="margin-top: 2rem;" fxLayoutGap="15px">
      <mat-form-field appearance="outline" fxFlex="49">
        <mat-label>Ημερομηνία λήξης</mat-label>
        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Επιλέξτε ημερομηνία και ώρα" [(ngModel)]="sendDate"
               (selectionchange)="changeEvent()"
               [min]="minDate" [max]="maxDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false"
                                 [stepHour]="1" [stepMinute]="1"
                                 [touchUi]="true" [enableMeridian]="false"
                                 [hideTime]="false">
        </ngx-mat-datetime-picker>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="49">
        <mat-label>
          Λόγοι
        </mat-label>
        <mat-select [(ngModel)]="selectedCause">
          <mat-option *ngFor="let cause of causes" [value]="cause">{{cause?.name}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>
  </div>
  <!--  -->

  <!--  restart-->
  <div *ngIf="mode === 'restart'" fxFlex="100">
    <div fxLayout="row" fxFlex="100" style="margin-top: 2rem;" fxLayoutGap="15px">
      <mat-form-field appearance="outline" fxFlex="49">
        <mat-label>Ημερομηνία έναρξης</mat-label>
        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Επιλέξτε ημερομηνία και ώρα" [(ngModel)]="sendDate"
               (selectionchange)="changeEvent()"
               [min]="minDate" [max]="maxDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false"
                                 [stepHour]="1" [stepMinute]="1"
                                 [touchUi]="true" [enableMeridian]="false"
                                 [hideTime]="false">
        </ngx-mat-datetime-picker>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="49">
        <mat-label>
          Mέθοδοι άρδευσης
        </mat-label>
        <mat-select [(ngModel)]="selectedMethod">
          <mat-option *ngFor="let method of methods" [value]="method">{{method?.name}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

  </div>
  <!--  -->

  <!--  stop-->
  <div *ngIf="mode === 'stop'" fxFlex="100">
    <div fxLayout="row" fxFlex="100" style="margin-top: 2rem;" fxLayoutGap="15px">
      <mat-form-field appearance="outline" fxFlex="49">
        <mat-label>Ημερομηνία λήξης</mat-label>
        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Επιλέξτε ημερομηνία και ώρα" [(ngModel)]="sendDate"
               (selectionchange)="changeEvent()"
               [min]="minDate" [max]="maxDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false"
                                 [stepHour]="1" [stepMinute]="1"
                                 [touchUi]="true" [enableMeridian]="false"
                                 [hideTime]="false">
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
  </div>
  <!---->

  <!--  start-->
  <div *ngIf="mode === 'start'" fxFlex="100">
    <div fxLayout="row" fxFlex="100" style="margin-top: 2rem;" fxLayoutGap="15px">
      <mat-form-field appearance="outline" fxFlex="49">
        <mat-label>Ημερομηνία έναρξης</mat-label>
        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Επιλέξτε ημερομηνία και ώρα" [(ngModel)]="sendDate"
               (selectionchange)="changeEvent()"
               [min]="minDate" [max]="maxDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false"
                                 [stepHour]="1" [stepMinute]="1"
                                 [touchUi]="true" [enableMeridian]="false"
                                 [hideTime]="false">
        </ngx-mat-datetime-picker>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="49">
        <mat-label>
          Mέθοδοι άρδευσης
        </mat-label>
        <mat-select [(ngModel)]="selectedMethod">
          <mat-option *ngFor="let method of methods" [value]="method">{{method?.name}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

  </div>
  <!--  -->
</div>
