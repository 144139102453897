<mat-card class="tableCard" fxFlex="100" [style.height.px]="tableHeight">
  <mat-card-header class="header" fxLayoutAlign="start center center">


    <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center center">
      <mat-form-field appearance="standard" fxFlex="70">
        <input matInput (keyup)="search($event.target)" placeholder="Αναζήτηση">
      </mat-form-field>

      <div fxFlex="30" fxLayoutAlign="end center center">
      <div *ngIf="actionIcon">
        <button mat-icon-button (click)="actionCallback();">
          <mat-icon>{{actionIcon}}</mat-icon>
        </button>
      </div>
      <mat-paginator #paginator [length]="dataSource.data.length"
                     [pageSize]="25"
                     [pageSizeOptions]="[5, 10, 25, 100]">

      </mat-paginator>
      </div>
    </div>


  </mat-card-header>

  <mat-card-content>
    <div [style.height.px]="tableHeight - 100" style="overflow-y: auto; width: 100%;" >
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width:100%;" >

      <div *ngFor="let column of displayColumns;">
      <ng-container *ngIf="column !== 'actions' && column !== 'is_active'  && column !== 'hasActiveIrrigation'" [matColumnDef]="column">
        <mat-header-cell fxLayoutAlign="center center" *matHeaderCellDef> {{getLabel(column)}} </mat-header-cell>
        <mat-cell style="margin-left: 10px;margin-right: 10px;" fxLayoutAlign="center center"  *matCellDef="let element"> {{formatField(element[column], column)}} </mat-cell>
      </ng-container>
      </div>

      <ng-container matColumnDef="is_active">
                <mat-header-cell fxFlex="15" fxLayoutAlign="center center" style="text-align: center" *matHeaderCellDef> Κατάσταση </mat-header-cell>
                <mat-cell fxFlex="15" fxLayoutAlign="center center" *matCellDef="let element">

                  <div fxLayoutAlign="start center">

                    <div class="circle green" *ngIf="element.is_active === 1 && (model !== 'sectors' && model !== 'squares')"></div>
                    <div class="circle red" *ngIf="(model !== 'sectors' && model !== 'squares') && (element.is_active === 0 || !element.is_active)"></div>


                    <div *ngIf="element.is_active === 1 && (model === 'sectors' || model === 'squares')">
                      <mat-icon style="color: blue;">water_drop</mat-icon>
                    </div>
                    <div *ngIf="(model === 'sectors' || model === 'squares') && (element.is_active === 0 || !element.is_active)">
                      <mat-icon style="color: gray;">water_drop</mat-icon>
                    </div>

                  </div>

                </mat-cell>
              </ng-container>

      <ng-container matColumnDef="hasActiveIrrigation">
                <mat-header-cell fxFlex="15" fxLayoutAlign="center center" style="text-align: center" *matHeaderCellDef> Κατάσταση </mat-header-cell>
                <mat-cell fxFlex="15" fxLayoutAlign="center center" *matCellDef="let element">

                  <div fxLayoutAlign="start center">

                    <div *ngIf="element.hasActiveIrrigation === 1 && element.is_paused === 0">
                      <mat-icon style="color: blue;">water_drop</mat-icon>
                    </div>
                    <div *ngIf=" element.hasActiveIrrigation === 1 && element.is_paused === 1">
                      <mat-icon style="color: red;">water_drop</mat-icon>
                    </div>

                  </div>

                </mat-cell>
              </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell fxFlex="15" fxLayoutAlign="start center" *matHeaderCellDef> Ενέργειες </mat-header-cell>
        <mat-cell fxFlex="15" fxLayoutAlign="start center" *matCellDef="let element">
          <div fxLayout="row">
            <button mat-icon-button matTooltip="Επεξεργασία" (click)="edit(element.id)">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true;"></mat-header-row>
      <mat-row class="tableRow" *matRowDef="let row; columns: displayColumns;" (click)="navigate(row)"></mat-row>
    </mat-table>
    </div>
  </mat-card-content>

  <mat-card-actions>

  </mat-card-actions>
</mat-card>
