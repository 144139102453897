<div fxLayout="column" class="container">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div>
      <h1>Προγραμματισμός</h1>
    </div>
  </div>
<mat-tab-group>
  <mat-tab label="Άρδευση"> Content 1 </mat-tab>
  <mat-tab label="Ειδοποιήσεις"> Content 2 </mat-tab>
</mat-tab-group>
</div>
