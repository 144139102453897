import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PersonService} from '../../../../services/person/person.service';
import {CommonService} from '../../../../services/common.service';
import {ToastMessagesService} from '../../../../services/toast-messages.service';
import {SelectionTableComponent} from '../../shared/selection-table/selection-table.component';
import {ConfirmOptionDialogComponent} from "../../shared/confirm-option-dialog/confirm-option-dialog.component";
import {FieldService} from "../../../../services/fields/field.service";

@Component({
  selector: 'app-union-split-dialog',
  templateUrl: './union-split-dialog.component.html',
  styleUrls: ['./union-split-dialog.component.scss']
})
export class UnionSplitDialogComponent implements OnInit {
  mode: number;
  field: any;
  renter: any;
  crop: any;
  irrigator: any;
  finalAcres: number;
  peopleColumns = [{key: 'selection', label: ''}, {key: 'firstname', label: 'Όνομα'}, {
    key: 'lastname',
    label: 'Επώνυμο'
  }, {
    key: 'midname',
    label: 'Πατρώνυμο'
  }];
  cropColumns = [{key: 'selection', label: ''}, {key: 'name', label: 'Καλλιέργεια'}];
  min: any;
  cropDisabled = true;
  renterDisabled = true;
  irrigatorDisabled = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialog: MatDialog,
              private fieldService: FieldService,
              public toastMessageService: ToastMessagesService,
              public dialogRef: MatDialogRef<UnionSplitDialogComponent>) {
    this.mode = data.mode;
    this.field = data.field;
    this.crop = data.field.crop_name;
    this.renter = data.field.renter_fullname;
    this.irrigator = data.field.irrigator_fullname;
    this.min = (data.field.acres - 1).toString();
  }

  displayCropName(field: any) {
    if (field.name) {
      return field.name;
    } else {
      return field;
    }
  }

  displayPersonFullName(person: any) {
    if (person.firstname) {
      return `${person.firstname} ${person.lastname} ${person.midname}`;
    } else {
      return person;
    }
  }

  ngOnInit(): void {
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  select(field: string) {
    switch (field) {
      case 'crop':
        this.selectionTable('crop', [], this.cropColumns, 'Επιλέξτε καλλιέργεια');
        break;
      case 'renter':
        this.selectionTable('renter', [], this.peopleColumns, 'Επιλέξτε ενοικιαστή');
        break;
      case 'irrigator':
        this.selectionTable('irrigator', [], this.peopleColumns, 'Επιλέξτε αρδευτή');
        break;
    }
  }

  selectionTable(model: string, dataSource: any, columns: any[], title: string) {
    const selection = this.dialog.open(SelectionTableComponent, {
      panelClass: 'myapp-no-padding-dialog',
      data: {
        dataSource,
        columns,
        model,
        title
      }
    });

    selection.afterClosed().subscribe(response => {
      if (response) {
        switch (model) {
          case 'crop':
            this.crop = response;
            this.field.crop_name = this.displayCropName(response);
            this.cropDisabled = false;
            break;
          case 'renter':
            this.renter = response;
            this.field.renter_fullname = this.displayPersonFullName(response);
            this.renterDisabled = false;

            break;
          case 'irrigator':
            this.irrigator = response;
            this.field.irrigator_fullname = this.displayPersonFullName(response);
            this.irrigatorDisabled = false;

            break;
        }
      }
    });
  }

  submit() {
    const save = this.dialog.open(ConfirmOptionDialogComponent, {
      data: {
        title: 'Ολοκλήρωση διαχωρισμού',
        message: 'Είστε σίγουροι ότι θέλετε να ολοκληρώσετε το διαχωρισμό?',
        YesBtn: 'Ναι',
        NoBtn: 'Όχι'
      }
    });

    save.afterClosed().subscribe(async response => {
      if (response) {
        const object = {
          main_field_id: this.field.main_field_id ? this.field.main_field_id : this.field.id,
          fathers_field_id: this.field.id,
          acres: +this.finalAcres,
          total_acres: +this.field.acres,
          renter_id: this.renter.id,
          irrigator_id: this.irrigator.id,
          crop_id: this.crop.id
        };
        
        try {
          await this.fieldService.fieldSplit(object);
          this.toastMessageService.toastMessages(`O διαχωρισμός ολοκληρώθηκε με επιτυχία`);
          this.close(true);
        } catch (e) {
          this.toastMessageService.toastErrorMessage(e.message);
        }
      }
    });
  }

  isDisabled() {
    return !this.finalAcres || !this.crop.name || !this.renter.firstname || !this.irrigator.lastname;
  }
}
