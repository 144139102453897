<div fxLayout="column" class="container">

  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" style="margin-top: 1rem;">
    <div fxFlex="100">
      <h1>Τετράγωνα τομέα {{sectorName}}</h1>
    </div>

    <button [disabled]="isButtonDisabled()" class="app-button" mat-icon-button color="primary" (click)="exportToExcel('Squares')" matTooltip="Εξαγωγή σε excel">
      <mat-icon>file_download</mat-icon>
    </button>

  </div>
  <mat-divider></mat-divider>

  <div fxFlex="100">
    <app-table #table [columns]="displayColumns" [param]="sectorId" [model]="model" [hasNavigation]="hasNavigation" [navigateTo]="navigateTo"></app-table>
  </div>
</div>
