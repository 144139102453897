import {Injectable} from '@angular/core';
import {Log} from '../app/models/Log';
import {CommonService} from './common.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private commonService: CommonService) {
  }

  async fetchLogs(): Promise<Log[]> {
    return await this.commonService.getRequest(`actions`);
  }

  async exportData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.commonService.getFileRequest('actions/export').subscribe((data: any) => {
        const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        resolve(url);
      }, error => {
       reject(error);
      });
    });
  }
}
