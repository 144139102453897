import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {CommonService} from '../../../../services/common.service';
import {MatPaginator} from '@angular/material/paginator';
import {PersonService} from '../../../../services/person/person.service';
import {FieldService} from '../../../../services/fields/field.service';
import {ToastMessagesService} from '../../../../services/toast-messages.service';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-selection-table',
  templateUrl: './selection-table.component.html',
  styleUrls: ['./selection-table.component.scss']
})
export class SelectionTableComponent implements OnInit, AfterViewInit {
  displayColumns: any[];
  model: string;
  dataSource: MatTableDataSource<any>;
  columns: any[];
  tableHeight: number;
  pending: boolean;
  public selection: SelectionModel<any>;
  isLoading: boolean;

  @ViewChild('paginator', {static: false}) paginator: MatPaginator;


  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<SelectionTableComponent>,
              private personService: PersonService,
              private fieldService: FieldService,
              public toastMessagesService: ToastMessagesService,
              public commonService: CommonService) {
    this.model = data.model;
    this.columns = data.columns;
    this.dataSource = new MatTableDataSource<any>([]);
    this.pending = true;
    this.isLoading = true;
    this.selection = new SelectionModel<any>(false, []);
  }

  async ngOnInit(): Promise<void> {
    try {
      this.tableHeight = window.innerHeight - 235;
      this.displayColumns = this.columns.map(c => c.key);
      await this.initDataSource();
      this.pending = false;
    } catch (e) {
      this.toastMessagesService.toastErrorMessage(e.message);
    }
  }

  ngAfterViewInit() {
    this.commonService.setPaginatorTexts(this.paginator);
  }

  async initDataSource() {
    if (this.model === 'crop') {
      await this.initCrops();
    } else {
      await this.initPeople();
    }

    this.dataSource.paginator = this.paginator;
  }

  async initCrops() {
    this.dataSource = new MatTableDataSource<any>(await this.fieldService.fetchFieldsCrops());
  }

  async initPeople() {
    this.dataSource = new MatTableDataSource<any>(await this.personService.getPeople());
  }

  formatField(field: any, column: string) {
    if (field === 'null' || field === undefined) {
      return '-';
    } else {
      if (typeof field === 'string') {
        return field && (field.length > 0) ? field.trim() : '-';
      } else {
        return field;
      }
    }
  }

  getLabel(column: string) {
    return this.columns.find(c => c.key === column).label;
  }

  search(target: any) {
    const value = target.value.toLowerCase().trim();
    this.dataSource.filter = value;
  }

  public closeDialog(data?): void {
    this.dialogRef.close(data);
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;

    // @ts-ignore
    const numRows = this.dataSource.data.length;

    return numSelected === numRows;
  }

  public masterToggle(): void {
    // @ts-ignore
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  selectRow(row: any) {
    this.selection.clear();
  }

  finalizeSelection() {
    const selected = this.selection.selected[0];
    this.closeDialog(selected);
  }

  isDisabled() {
    return this.selection.selected.length === 0;
  }
}
