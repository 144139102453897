<div fxFlex="100" fxLayout="column" style="width: 1200px; height: 750px; overflow-x:hidden;">

  <div style="padding: 1rem;" fxLayout="row">
    <h3 fxFlex="100">{{data.title}}</h3>
    <button mat-icon-button [disabled]="isDisabled()" [style.color]="isDisabled() ? 'gray' : 'green'" (click)="finalizeSelection()" style="color:green;">
      <mat-icon>done</mat-icon>
    </button>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

   <mat-card class="tableCard mat-elevation-z0" fxFlex="100" [style.height.px]="600">
      <mat-card-header class="header" fxLayoutAlign="start center center">


        <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center center">


          <div fxFlex="60">
          <mat-form-field appearance="standard">
            <input matInput (keypress)="search($event.target)" placeholder="Αναζήτηση">
          </mat-form-field>
          </div>
          <div fxFlexAlign="end center center">
            <mat-paginator #paginator [length]="dataSource.data.length"
                           [pageSize]="25"
                           [pageSizeOptions]="[5, 10, 25, 100]"
            >

            </mat-paginator>
          </div>
        </div>


      </mat-card-header>

      <mat-card-content>
        <div [style.height.px]="550" style="overflow-y: auto; width: 100%;" fxLayout="column">
          <div *ngIf="pending" fxFlex="100" fxLayoutAlign="start start" style="z-index: 999">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width:100%;">

            <ng-container matColumnDef="selection">
              <mat-header-cell fxFlex="5"  *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null" disabled="true" [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell fxFlex="5"  *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation(); selectRow(row)" (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)">
                </mat-checkbox>
              </mat-cell>
            </ng-container>
            <div *ngFor="let column of displayColumns;">
              <ng-container *ngIf="column !== 'selection'"
                            [matColumnDef]="column">
                <mat-header-cell fxLayoutAlign="center center"
                                 *matHeaderCellDef> {{getLabel(column)}} </mat-header-cell>
                <mat-cell fxLayoutAlign="center center"
                          *matCellDef="let element"> {{formatField(element[column], column)}} </mat-cell>
              </ng-container>
            </div>

            <mat-header-row *matHeaderRowDef="displayColumns; sticky: true;"></mat-header-row>
            <mat-row class="tableRow" *matRowDef="let row; columns: displayColumns;" (click)="selection.toggle(row)"></mat-row>
          </mat-table>

        </div>
      </mat-card-content>

      <mat-card-actions>

      </mat-card-actions>
    </mat-card>

</div>
