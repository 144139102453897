import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoadingService} from '../../../../services/loading.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
  extraText: string;
  loading: boolean;
  subscription: Subscription;
  textSubscription: Subscription;

  constructor(private loadingService: LoadingService) {
    this.loading = false;
  }

  ngOnInit(): void {
    this.subscribeToLoadingEvents();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.textSubscription.unsubscribe();
    }
  }

  subscribeToLoadingEvents() {
    this.subscription = this.loadingService.observe().subscribe(status => {
      this.loading = status;
    });

    this.textSubscription = this.loadingService.observeText().subscribe(text => {
      this.extraText = text;
    });
  }

}
