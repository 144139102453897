import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  @ViewChild('table', {static: true}) table: any;
  displayColumns = [{key: 'id', label: 'Αναγνωριστικό'},
    {key: 'firstname', label: 'Όνομα'}, {key: 'lastname', label: 'Επώνυμο'},
    {key: 'username', label: 'Όνομα χρήστη'}, {key: 'displayRoles', label: 'Ρόλοι'}, {key: 'sectors', label: 'Τομείς'},
    {
      key: 'is_active',
      label: 'Κατάσταση'
    }, {key: 'actions', label: 'Ενέργειες'}];
  model = 'users';

  constructor(public dialog: MatDialog,
              public router: Router) {
  }

  ngOnInit(): void {
  }

  async navigateToUserCreationPage() {
    await this.router.navigate(['start/create-user']);
  }

  async navigateToEditUserPage(user: any) {
    await this.router.navigate([`start/edit-user/${user.id}`]);
  }

  exportToExcel(fileName: string) {
    this.table.exportToExcel(fileName);
  }

  isButtonDisabled() {
    return this.table.dataSource.filteredData.length === 0;
  }
}
