import {Injectable} from '@angular/core';
import {CommonService} from './common.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private commonService: CommonService) {
  }

  async fetchAll() {
    return await this.commonService.getRequest(`notification/user`);
  }

  async fetchPending() {
    return await this.commonService.getRequest(`notification/web`);
  }

  async fetchById(notificationId: number) {
    return await this.commonService.getRequest(`notification/${notificationId}`);
  }

  async fetchPendingById(notificationId) {
    return await this.commonService.getRequest(`notification/web/${notificationId}`);
  }

  async create(objectToSend: any) {
    return await this.commonService.postRequest(`notification/create`, objectToSend);
  }

  async deleteNotification(notificationId: number) {
    return await this.commonService.deleteRequest(`notification/web/${notificationId}`);
  }
}
