import {Injectable} from '@angular/core';
import {CommonService} from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private commonService: CommonService) {
  }

  async fetchSettings() {
    const settings = await this.commonService.getRequest(`configuration`);
    return settings[0];
  }

  async updateCrop(object: any) {
    await this.commonService.putRequest(`configuration/crop`, object);
  }

  async updateIrrigation(object: any) {
    await this.commonService.putRequest(`configuration/irrigation`, object);
  }

  async updateRegister(object: any) {
    await this.commonService.putRequest(`configuration/register`, object);
  }

  async updateRadius(object: any) {
    await this.commonService.putRequest(`configuration/radius`, object);
  }
}
