import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmOptionDialogComponent } from '../../../shared/confirm-option-dialog/confirm-option-dialog.component';
import { ToastMessagesService } from '../../../../../services/toast-messages.service';
import * as moment from 'moment';
import { FieldService } from '../../../../../services/fields/field.service';

@Component({
  selector: 'app-field-actions-dialog',
  templateUrl: './field-actions-dialog.component.html',
  styleUrls: ['./field-actions-dialog.component.scss']
})
export class FieldActionsDialogComponent implements OnInit {
  field: any;
  title: string;
  mode: string;
  causes: [];
  methods: [];
  sendDate: any;
  maxDate: any;
  minDate: Date;
  selectedCause: any;
  selectedMethod: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialog: MatDialog,
              private fieldService: FieldService,
              public toastMessageService: ToastMessagesService,
              public dialogRef: MatDialogRef<FieldActionsDialogComponent>) {
    this.title = data.title;
    this.field = data.field;
    this.mode = data.mode;
    this.causes = data.causes;
    this.methods = data.methods;
  }

  ngOnInit(): void {
    this.minDate = this.getMin();
    this.maxDate = new Date(new Date().setHours(23, 59, 59, 999));
  }

  getMin(): Date {
    const options = JSON.parse(localStorage.getItem('squareOptions'));
    if (this.mode === 'start') {
      return moment(options.start_date, 'DD-MM-YYYY HH:mm').toDate();
    }
    else if (this.mode === 'pause') {
      return moment(this.field.start_time, 'DD-MM-YYYY HH:mm').toDate();
 }
    else if (this.mode === 'restart') {
      return moment(this.field.end_time, 'DD-MM-YYYY HH:mm').toDate();
 }
    else if (this.mode === 'stop'){
      // if not paused
      if (this.field.is_paused === 0) {
        return moment(this.field.start_time, 'DD-MM-YYYY HH:mm').toDate();
      }
      // if paused
      return moment(this.field.end_time, 'DD-MM-YYYY HH:mm').toDate();
    }
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  submit() {
    let title = '';
    let message = '';
    switch (this.mode) {
      case 'start':
        title = 'Έναρξη άρδευσης';
        message = 'Είστε σίγουροι ότι θέλετε να ξεκινήσετε την άρδευση του αγροτεμαχίου?';
        break;
      case 'stop':
        title = 'Λήξη άρδευσης';
        message = 'Είστε σίγουροι ότι θέλετε να τερματίσετε την άρδευση του αγροτεμαχίου?';

        break;
      case 'pause':
        title = 'Παύση άρδευσης';
        message = 'Είστε σίγουροι ότι θέλετε να θέσετε την άρδευση του αγροτεμαχίου σε παύση?';
        break;
      case 'restart':
        title = 'Επανέναρξη άρδευσης';
        message = 'Είστε σίγουροι ότι θέλετε να ξαναξεκινήσετε την άρδευση του αγροτεμαχίου?';
        break;
    }
    const confirm = this.dialog.open(ConfirmOptionDialogComponent, {
      data: {
        title,
        message,
        YesBtn: 'Ναι',
        NoBtn: 'Όχι'
      }
    });

    confirm.afterClosed().subscribe(async response => {
      if (response) {
        try {
          await this.executeAction();
          this.toastMessageService.toastMessages(`Η ενέργειά σας ολοκληρώθηκε με επιτυχία`);
          this.close(true);
        } catch (e) {
          this.toastMessageService.toastErrorMessage(e.error.message ? e.error.message : e.message);
        }
      }
    });
  }

  async executeAction() {
    switch (this.mode) {
      case 'start':
        const objectStart = {
          field_id: this.field.id,
          irrigation_method_id: this.selectedMethod.id,
          start_time: moment(this.sendDate).format('YYYY-MM-DD HH:mm'),
          square_id: this.field.square_id,
        };
        await this.fieldService.startField(objectStart);
        break;
      case 'stop':
        const objectStop = {
          field_id: this.field.id,
          irrigation_id: this.field.irrigation_id,
          end_time: moment(this.sendDate).format('YYYY-MM-DD HH:mm')
        };
        await this.fieldService.stopField(objectStop);
        break;
      case 'pause':
        const object = {
          cause_id: this.selectedCause.id,
          irrigation_id: this.field.irrigation_id,
          end_time: moment(this.sendDate).format('YYYY-MM-DD HH:mm')
        };
        await this.fieldService.pauseField(object);
        break;
      case 'restart':
        const objectRestart = {
          field_id: this.field.id,
          irrigation_method_id: this.selectedMethod.id,
          main_irrigation_id: this.field.irrigation_id,
          start_time: moment(this.sendDate).format('YYYY-MM-DD HH:mm'),
          square_id: this.field.square_id,
        };
        await this.fieldService.restartField(objectRestart);
        break;
    }
  }

  isDisabled() {
    switch (this.mode) {
      case 'start':
        return !this.selectedMethod || !this.sendDate;
      case 'stop':
        return !this.sendDate;
      case 'pause':
        return !this.selectedCause || !this.sendDate;
      case 'restart':
        return !this.selectedMethod || !this.sendDate;
    }
  }

  changeEvent() {

  }
}
