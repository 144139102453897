import {Injectable} from '@angular/core';
import {CommonService} from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  constructor(private commonService: CommonService) {
  }

  async searchPerson(field, value) {
    try {
      return await this.commonService.getRequest(`person/searchAll?searchField=${field}&searchValue=${value}`);
    } catch (e) {
      console.log(e);
    }
  }

  async searchByLastName(lastName: string) {
    try {
      return await this.commonService.getRequest(`person/search?lastname=${lastName}`);
    } catch (e) {
      console.log(e);
    }
  }

  async addPerson(body: any) {
    try {
      return await this.commonService.postRequest(`person`, body);
    } catch (e) {
      console.log(e);
    }
  }

  async getPersonById(id: number) {
    try {
      return await this.commonService.getRequest(`person/${id}`);
    } catch (e) {
      console.log(e);
    }
  }

  async updatePerson(id: number, body: any) {
    try {
      return await this.commonService.putRequest(`person/${id}`, body);
    } catch (e) {
      console.log(e);
    }
  }

  async getPeople() {
    try {
      return await this.commonService.getRequest(`person`);
    } catch (e) {
      console.log(e);
    }
  }

  displayName(person) {
    return `${person.firstname} ${person.lastname} ${person.midname}`;
  }
}
