import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {NotificationDialogComponent} from "./notification-dialog/notification-dialog.component";
import {CreateNotificationComponent} from "./create-notification/create-notification.component";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  displayColumns = [{key: 'title', label: 'Τίτλος'}, {key: 'date_created', label: 'Ημ. Δημιουργίας'}, {
    key: 'send_date',
    label: 'Ημ.Αποστολής'
  }];
  model = 'notifications';

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  createNotification() {
    this.dialog.open(CreateNotificationComponent, {
      // width: '500px',
      height: '800px',
      disableClose: true
    });
  }

  async showMessage(notificationId?: number, canDelete?: boolean) {
    const dialog = this.dialog.open(NotificationDialogComponent, {
      disableClose: true,
      data: {
        canDelete,
        id: notificationId
      }
    });

    dialog.afterClosed().subscribe(response => {
      if (response) {
        window.location.reload();
      }
    });
  }
}
