<div fxLayout="column" class="container">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxFlex="100">
      <h1>Ενέργειες χρηστών</h1>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div fxFlex="100">
    <app-table [columns]="displayColumns" [model]="model" [actionIcon]="actionIcon" (actionCallBack)="export()"></app-table>
  </div>
</div>
