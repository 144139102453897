import {Injectable} from '@angular/core';
import {CommonService} from '../common.service';
import {LoginUser} from '../../app/models/LoginUser';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: any;

  constructor(private commonService: CommonService) {
  }

  async login(user: LoginUser) {
    return await this.commonService.postRequest('authentication', user);
  }

  validateUser() {
    return !!localStorage.getItem('token');
  }

  public getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  async getUserByToken() {
    if (this.user) {
      return this.user;
    } else {
      this.user = await this.commonService.getRequest(`users/me`);
      return this.user;
    }
  }

  public async getUsers() {
    try {
      return await this.commonService.getRequest(`users`);
    } catch (e) {
      console.log(e);
    }
  }

  public async getUserById(userId: number) {
    try {
      return await this.commonService.getRequest(`users/${userId}`);
    } catch (e) {
      console.log(e);
    }
  }

  public async createUser(model: any) {
    return await this.commonService.postRequest(`users`, model);
  }

  public async updateUser(model: any) {
    try {
      return await this.commonService.putRequest(`users`, model);
    } catch (e) {
      console.log(e);
    }
  }

  enableUser(userId: number) {
    console.log(userId);
    // this.commonService.getRequest(``);
  }

  disableUser(userId: number) {
    console.log(userId);
    // this.commonService.getRequest(``);
  }

  deleteUser(userId: number) {
    this.commonService.deleteRequest(`users/${userId}`);
  }

  async fetchUserByPersonId(personId: number) {
    return await this.commonService.getRequest(`users/person/${personId}`);
  }


  public async fetchDisabledCropUsers() {
    try {
      return await this.commonService.getRequest(`users/disabledCrop`);
    } catch (e) {
      console.log(e);
    }
  }

  public async fetchDisabledIrrigationUsers() {
    try {
      return await this.commonService.getRequest(`users/disabledIrrigation`);
    } catch (e) {
      console.log(e);
    }
  }


  public async updateUsersConfiguration(data: any) {
    try {
      return await this.commonService.putRequest(`users/disabledConfiguration`, data);
    } catch (e) {
      console.log(e);
    }
  }

}
