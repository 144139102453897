import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PersonService} from '../../../services/person/person.service';
import {Person} from '../../models/Person';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ConfirmOptionDialogComponent} from '../shared/confirm-option-dialog/confirm-option-dialog.component';
import {Router} from '@angular/router';
import {LoadingService} from '../../../services/loading.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {CommonService} from '../../../services/common.service';

@Component({
  selector: 'app-person-registry',
  templateUrl: './person-registry.component.html',
  styleUrls: ['./person-registry.component.scss']
})
export class PersonRegistryComponent implements OnInit {
  @ViewChild('filters', {static: true}) container: ElementRef;
  people: Person[];
  searchPerson: any;
  firstName: string;
  lastName: string;
  midName: string;
  email: string;
  vatNum: string;
  mobileNumber: string;
  phoneNumber: string;
  hasFilter: boolean;
  searchPressed: boolean;
  userDataSource: MatTableDataSource<Person>;
  displayColumns = [{key: 'toeb_id', label: 'Αναγνωριστικό'},
    {key: 'firstname', label: 'Όνομα'}, {key: 'lastname', label: 'Επώνυμο'},
    {key: 'midname', label: 'Όνομα πατρός'}, {key: 'vat_number', label: 'Α.Φ.Μ'},
    {
      key: 'mobile_number',
      label: 'Κινητό'
    }, {key: 'phone_number', label: 'Τηλέφωνο'}, {key: 'actions', label: 'Ενέργειες'}];
  tableHeight: number;
  pending: boolean;

  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  @ViewChild('table', {static: true}) table: any;
  // @ViewChild(MatSort) sort: MatSort;
  //
  // displayedColumns: string[] = ['id', 'firstname', 'lastname', 'midname', 'vat_number', 'email', 'mobile_number', 'phone_number'];
  // dataSource: MatTableDataSource<Person>;

  constructor(private personService: PersonService,
              private loadingService: LoadingService,
              public commonService: CommonService,
              public dialog: MatDialog, public router: Router) {
    this.pending = true;
    this.userDataSource = new MatTableDataSource<any>();
    this.tableHeight = window.innerHeight - 290;
    this.lastName = '';
    this.people = [];
    this.hasFilter = false;
    this.searchPressed = false;
  }

  async ngOnInit(): Promise<void> {
    // try {
    //   this.loadingService.setLoading(true);
    //   await this.fetchPeople();
    // } catch (e) {
    //   console.log(e);
    // } finally {
    //   this.pending = false;
    //   this.loadingService.setLoading(false);
    // }
  }

  async fetchPeople() {
    this.people = await this.personService.getPeople();
  }

  formatField(field: string) {
    return field && field !== 'null' ? field.trim() : '-';
  }

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  async searchRegistryByLastName() {
    try {
      this.loadingService.setLoading(true);

      this.searchPressed = true;
      const inputs = this.container.nativeElement.querySelectorAll('input');
      for (const input of inputs) {
        if (input.value !== '') {
          this.hasFilter = true;
          break;
        }
      }

      if (!this.hasFilter) {
        const message = 'Πρόκειται να γίνει αναζήτηση χωρίς κριτήρια. Η διαδικασία μπορεί να διαρκέσει μερικά λεπτά. Θέλετε να συνεχίσετε;';
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {message};
        const dialogRef = this.dialog.open(ConfirmOptionDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async result => {
          if (result) {
            this.people = await this.personService.searchByLastName(this.lastName);
          }
        });
      } else {
        const inputFields = this.container.nativeElement.querySelectorAll('input');
        for (const input of inputFields) {
          input.value = '';
        }
        this.people = await this.personService.searchByLastName(this.lastName);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.loadingService.setLoading(false);
    }
  }

  async navigateToPersonCreation() {
    await this.router.navigate(['start/create-person']);
  }

  async editPerson(person: any) {
    await this.router.navigate([`start/edit-person/${person.id}`]);
  }

  search(target: any) {
    const value = target.value.toLowerCase().trim();
    this.userDataSource.filter = value;
  }

  exportToExcel(fileName: string) {
    this.table.exportToExcel(fileName);
  }

  isButtonDisabled() {
    return this.table.dataSource.filteredData.length === 0;
  }
}
