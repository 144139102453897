<div fxLayout="column" fxFlex="100" style="width: 800px;">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center center">
    <label fxFlex="100"
           style="justify-content: flex-start; align-items: flex-start; font-size: 1rem;">{{data.title}}</label>
    <button mat-icon-button [style.color]="isDisabled() ? 'gray' : 'green'" [disabled]="isDisabled()" (click)="submit()">
      <mat-icon>done</mat-icon>
    </button>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div fxLayout="row" fxFlex="100" style="margin-top: 2rem;">
    {{data.message}}
  </div>

  <div fxLayout="row" fxFlex="100" style="margin-top: 2rem;">
    <mat-form-field appearance="outline">
      <mat-label>Κωδ. Αγροτεμαχίου</mat-label>
      <input matInput [(ngModel)]="field.id" disabled>
    </mat-form-field>
  </div>

  <div fxLayout="row wrap" fxFlex="100" style="margin-top: 2rem;" fxLayoutGap="15px">
    <mat-form-field appearance="outline" fxFlex="49">
      <mat-label>Αρχική έκταση</mat-label>
      <input matInput [(ngModel)]="field.acres" disabled>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="49">
      <mat-label>Έκταση νέου αγροτεμαχίου</mat-label>
      <input matInput [(ngModel)]="finalAcres" [min]="min"
             (keyup)="finalAcres > min ? finalAcres=min : finalAcres" type="number" required>
    </mat-form-field>
  </div>

  <div fxLayout="row wrap" fxFlex="100" style="margin-top: 2rem;" fxLayoutGap="15px">

    <mat-form-field appearance="outline" fxFlex="90">
      <mat-label>Καλλιέργεια</mat-label>
      <input matInput [(ngModel)]="field.crop_name" [disabled]="cropDisabled" readonly>
    </mat-form-field>
    <div>
      <button mat-icon-button (click)="select('crop')">
        <mat-icon>edit</mat-icon>
      </button>
    </div>

    <mat-form-field appearance="outline" fxFlex="90">
      <mat-label>Ενοικιαστής</mat-label>
      <input matInput [(ngModel)]="field.renter_fullname" [disabled]="renterDisabled" readonly>
    </mat-form-field>
    <div>
      <button mat-icon-button (click)="select('renter')">
        <mat-icon>edit</mat-icon>
      </button>
    </div>

    <mat-form-field appearance="outline" fxFlex="90">
      <mat-label>Αρδευτής</mat-label>
      <input matInput [(ngModel)]="field.irrigator_fullname" [disabled]="renterDisabled" readonly>
    </mat-form-field>
    <div>
      <button mat-icon-button (click)="select('irrigator')">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>


</div>


