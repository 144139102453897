<div style="width: 100%; overflow: hidden; position: absolute; top: 0; left: 0; z-index: 500" *ngIf="loading">

  <div fxLayout="row" fxFlex="100" style="width:100%; height: 100vh; background-color: gray; opacity: 0.4"></div>

  <div style="position: absolute; top: 40%; left: 45%; width: 20%; height: 20%">
    <img src="assets/general/loading/loading-drop.gif" alt="loading" style="max-height: 100%; max-width: 100%;"/>
  </div>

  <div style="position: absolute;top: 60%;left: 30%;" *ngIf="extraText">
    <div style="justify-content: center;text-align: center;">
     {{extraText}}
    </div>
  </div>

</div>
