<div fxLayout="column" fxFlex="100">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center center">
    <label fxFlex="100"
      style="justify-content: flex-start; align-items: flex-start; font-size: 1rem;">{{this.title}}</label>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div fxLayout="row" fxFlex="100" style="margin-top: 2rem;">
    <h4>{{message}}</h4>
  </div>

  <div fxLayout="row" fxFlex="100">
    <!--  Stop-->

    <div *ngIf="mode === 0" fxLayout="column" fxFlex="100" style="padding: 1rem;">

      <div fxLayout="row" fxFlex="100">
        <label fxFlex="50">Ημερομηνία λήξης:</label>
        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Επιλέξτε ημερομηνία" [(ngModel)]="date"
          [min]="minDate" [max]="maxDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false" [touchUi]="true"
          [enableMeridian]="false" [hideTime]="false">
        </ngx-mat-datetime-picker>
      </div>

      <!-- <div fxLayout="row" fxFlex="100" style="margin-top: 1rem;" fxLayoutAlign="start center">
        <label fxFlex="50" style="text-align: center; align-items: center; justify-content: center">Ώρα λήξης:</label>
        <div fxFlex="50" style="text-align: center" fxLayoutAlign="center center">
          <ngx-mat-timepicker fxFlex="100" fxLayoutAlign="center center" [(ngModel)]="date" [showSpinners]="false">
          </ngx-mat-timepicker>
        </div>
      </div> -->
    </div>
    <!---->

    <!--    Start-->
    <div *ngIf="mode === 1" fxLayout="column" fxFlex="100" style="padding: 1rem;">

      <div fxLayout="row" fxFlex="100">
        <label fxFlex="50">Ημερομηνία έναρξης:</label>
        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Επιλέξτε ημερομηνία" [(ngModel)]="date"
          [min]="minDate" [max]="maxDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false" [touchUi]="true"
          [enableMeridian]="false" [hideTime]="false">
        </ngx-mat-datetime-picker>
      </div>

      <!-- <div fxLayout="row" fxFlex="100" style="margin-top: 0.5rem;" fxLayoutAlign="start center">
        <label fxFlex="50" style="text-align: center; align-items: center; justify-content: center">Ώρα
          έναρξης:</label>
        <div fxFlex="50" style="text-align: center" fxLayoutAlign="center center">
          <ngx-mat-timepicker fxFlex="100" fxLayoutAlign="center center" [(ngModel)]="date" [showSpinners]="false">
          </ngx-mat-timepicker>
        </div> -->
      <!-- </div> -->

      <div fxLayout="row" fxFlex="100" *ngIf="model === 'sectors'">
        <label fxFlex="50">Εκτιμώμενη ημερομηνία λήξης:</label>
        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Επιλέξτε ημερομηνία" [(ngModel)]="dateEnd"
          [min]="minDate" [max]="estEnd" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false" [touchUi]="true"
          [enableMeridian]="false" [hideTime]="false">
        </ngx-mat-datetime-picker>
      </div>
<!-- 
      <div fxLayout="row" fxFlex="100" style="margin-top: 0.5rem;" fxLayoutAlign="start center"
        *ngIf="model === 'sectors'">
        <label fxFlex="50" style="text-align: center; align-items: center; justify-content: center">Εκτιμώμενη ώρα
          λήξης:</label>
        <div fxFlex="50" style="text-align: center" fxLayoutAlign="center center">
          <ngx-mat-timepicker fxFlex="100" fxLayoutAlign="center center" (ngModelChange)="checkTimes()"
            [(ngModel)]="dateEnd" [showSpinners]="false"></ngx-mat-timepicker>
        </div>
      </div> -->

      <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center">
        <label fxFlex="50">Τρέχουσα παροχή νερού:</label>
        <mat-form-field>
          <input matInput [(ngModel)]="waterSupply" type="number">
        </mat-form-field>
      </div>

    </div>
    <!--    -->

    <!--    Edit-->
    <div *ngIf="mode === 2" fxLayout="column" fxFlex="100" style="padding: 1rem;">
      <div fxLayout="row" fxFlex="100">
        <label fxFlex="50">Ημερομηνία έναρξης:</label>
        <!--        <label fxFlex="50" style="text-align: center">{{currentDate}}</label>-->
        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Επιλέξτε ημερομηνία" [(ngModel)]="date"
          [min]="minDate" [max]="maxDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false" [touchUi]="true"
          [enableMeridian]="false" [hideTime]="false">
        </ngx-mat-datetime-picker>
      </div>

      <!-- <div fxLayout="row" fxFlex="100" style="margin-top: 1rem;" fxLayoutAlign="start center">
        <label fxFlex="50" style="text-align: center; align-items: center; justify-content: center">Ώρα έναρξης:</label>
        <div fxFlex="50" style="text-align: center" fxLayoutAlign="center center">
          <ngx-mat-timepicker fxFlex="100" fxLayoutAlign="center center" [(ngModel)]="date" [showSpinners]="false">
          </ngx-mat-timepicker>
        </div>
      </div> -->

      <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center">
        <label fxFlex="50">Τρέχουσα παροχή νερού:</label>
        <mat-form-field>
          <input matInput [(ngModel)]="waterSupply" type="number">
        </mat-form-field>
      </div>
    </div>
    <!--    -->
  </div>


  <div fxLayout="row" fxFlex="100" fxLayoutAlign="end center center">
    <button mat-flat-button *ngIf="mode === 0" [disabled]="isLoading" (click)="stop()" [class.spinner]="isLoading">
      Λήξη
    </button>
    <button mat-flat-button *ngIf="mode === 1" [disabled]="isLoading || isInValid()" (click)="start()"
      [class.spinner]="isLoading">
      Έναρξη
    </button>
    <button mat-flat-button *ngIf="mode === 2" [disabled]="isLoading" [class.spinner]="isLoading" (click)="edit()">
      Αποθήκευση
    </button>
    <button mat-flat-button (click)="close()" [disabled]="isLoading">Ακύρωση</button>
  </div>
</div>
