<!--<div fxLayout="column" style="width: 30vw;">-->
<!--  <div fxLayout="row" fxLayoutAlign="center center center">-->
<!--    {{data.message}}-->
<!--  </div>-->
<!--  <div fxLayout="row" style="margin: 20px;">-->
<!--    <div>-->
<!--      <button mat-raised-button (click)="onConfirm()">{{data.YesBtn}}</button>-->
<!--    </div>-->
<!--    <div>-->
<!--      <button mat-raised-button (click)="onDismiss()">{{data.NoBtn}}</button>-->
<!--    </div>-->
<!--    <div *ngIf="data.hasDecline">-->
<!--      <button mat-raised-button (click)="onCancel()">data.DeclineBtn</button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->



<div fxLayout="column" fxFlex="100">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center center">
    <label fxFlex="100" style="justify-content: flex-start; align-items: flex-start; font-size: 1rem;">{{data.title}}</label>
    <button mat-icon-button (click)="onDismiss()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div fxLayout="row" fxFlex="100" style="margin-top: 2rem;">
    {{data.message}}
  </div>

  <div fxLayout="row" style="margin-top: 2rem;" fxFlex="100" fxLayoutGap="5px" fxLayoutAlign="end center center">
    <button mat-flat-button style="color: red;" (click)="onDismiss()">{{data.NoBtn}}</button>
    <button  mat-flat-button (click)="onConfirm()">{{data.YesBtn}}</button>
    <button class="btn" *ngIf="data.hasDecline" mat-flat-button (click)="onCancel()">{{data.DeclineBtn}}</button>
  </div>
</div>



