import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-option-dialog',
  templateUrl: './confirm-option-dialog.component.html',
  styleUrls: ['./confirm-option-dialog.component.scss']
})
export class ConfirmOptionDialogComponent implements OnInit {
  constructor( private dialogRef: MatDialogRef<ConfirmOptionDialogComponent>,
               @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
  }

  /**
   * confirm option (yes)
   */
  onConfirm() {
    this.dialogRef.close(true);
  }

  /**
   * dismiss option (no)
   */
  onDismiss() {
    this.dialogRef.close(false);
  }

  onCancel() {

  }
}
