import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LoadingService} from '../../../services/loading.service';
import {ToastMessagesService} from '../../../services/toast-messages.service';
import {ConfigurationService} from '../../../services/configuration.service';
import {Router} from '@angular/router';
import {FileService} from '../../../services/file.service';
import {MatDialog} from '@angular/material/dialog';
import {DisabledConfigurationDialogComponent} from '../disabled-configuration-dialog/disabled-configuration-dialog.component';
import {UserService} from '../../../services/user/user.service';

interface Configuration {
    id: number;
    is_disabled_crop: boolean;
    is_disabled_irrigation: boolean;
    current_mobile_version: string;
    current_server_version: string;
    meteo_station_existence: boolean;
    meteo_station_urls: string;
    radius: number;
    is_register_enabled: boolean;
}

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
    @ViewChild('importFile') importFile: ElementRef;
    @ViewChild('importFile2') importFile2: ElementRef;
    configuration: Configuration;
    cropTitle: string;
    irrigationTitle: string;
    loadingText: string;
    registrationTitle: string;
    pending: boolean;
    isLoading: boolean;

    arrayBuffer: any;
    // Αρχείο μητρώου
    // registryExcelLoaded: boolean;
    registryExcelUploaded: boolean;
    registryFile: File;

    // Αρχείο αγροτεμαχίου
    // fieldsExcelLoaded: boolean;
    fieldsExcelUploaded: boolean;
    fieldsFile: File;

    file: File;
    dataFromFile: any[] = [];

    constructor(
        public loadingService: LoadingService,
        public toastMessageService: ToastMessagesService,
        private configurationService: ConfigurationService,
        public router: Router,
        private fileService: FileService,
        private matDialog: MatDialog,
        private userService: UserService
    ) {
        this.cropTitle = '';
        this.irrigationTitle = '';
        this.loadingText = 'Παρακαλώ περιμένετε, η διαδικασία μπορεί να διαρκέσει λίγο χρόνο, παραμείνετε στη σελίδα.';
        this.registrationTitle = '';
        this.pending = true;
        this.isLoading = false;
    }

    async ngOnInit(): Promise<void> {
        try {
            this.loadingService.setLoading(true);
            this.configuration = await this.initConfiguration();
            this.formatMessages();
            this.pending = false;
        } catch (e) {
            this.toastMessageService.toastErrorMessage(e.message);
        } finally {
            this.loadingService.setLoading(false);
        }
    }

    formatMessages() {
        this.cropTitle = 'Απενεργοποίηση επεξεργασίας καλλιέργειας';
        this.irrigationTitle = 'Απενεργοποίηση επεξεργασίας άρδευσης';
        this.registrationTitle = 'Ενεργοποίηση διαδικασίας εγγραφής';
    }

    async onChange(field: string) {
        try {
            const object = {
                id: 1,
                is_disabled_crop: null,
                is_disabled_irrigation: null,
                is_register_enabled: null
            };
            object[field] = !this.configuration[field] === true ? 1 : 0;

            if (field === 'is_disabled_crop') {
                await this.configurationService.updateCrop(object);
            } else if (field === 'is_disabled_irrigation') {
                await this.configurationService.updateIrrigation(object);
            } else {
                // await this.configurationService.updateCrop(object);
            }

        } catch (e) {
            this.toastMessageService.toastErrorMessage(e.message);
        } finally {
            this.isLoading = false;
        }
    }

    async initConfiguration() {
        const configuration = await this.configurationService.fetchSettings();
        const c: Configuration = {
            id: configuration.id,
            is_disabled_crop: !(!configuration.is_disabled_crop || configuration.is_disabled_crop === 0),
            is_disabled_irrigation: !(!configuration.is_disabled_irrigation || configuration.is_disabled_irrigation === 0),
            is_register_enabled: !(!configuration.is_register_enabled || configuration.is_register_enabled === 0),
            current_mobile_version: configuration.current_mobile_version,
            current_server_version: configuration.current_server_version,
            meteo_station_existence: !(!configuration.meteo_station_existence || configuration.meteo_station_existence === 0),
            meteo_station_urls: configuration.meteo_station_urls,
            radius: configuration.radius
        };
        return c;
    }

    async updateRadius() {
        try {
            this.isLoading = true;
            await this.configurationService.updateRadius({id: 1, radius: this.configuration.radius});
            this.toastMessageService.toastMessages(`Η ακτίνα άλλαξε με επιτυχία`);
        } catch (e) {
            this.toastMessageService.toastErrorMessage(e.message);
        } finally {
            this.isLoading = false;
        }
    }

    browseFiles(fileName: string) {
        switch (fileName) {
            case 'registry':
                this.importFile.nativeElement.click();
                break;
            case 'fields':
                this.importFile2.nativeElement.click();
        }
    }

    incomingFile($event: any) {
        if ($event.target.files.length === 0) {
            return;
        }

        switch ($event.target.name) {
            case 'registry':
                this.registryFile = $event.target.files[0];
                // this.registryExcelLoaded = true;
                break;
            case 'fields':
                this.fieldsFile = $event.target.files[0];
                // this.fieldsExcelLoaded = true;
                break;
        }
    }


    removeFile(fileName: string) {
        switch (fileName) {
            case 'registry':
                this.registryFile = null;
                // this.registryExcelLoaded = false;
                this.importFile.nativeElement.value = '';
                break;
            case 'fields':
                this.fieldsFile = null;
                // this.fieldsExcelLoaded = false;
                this.importFile2.nativeElement.value = '';
                break;
        }
    }

    async uploadFile(fileName: string, url: string) {
        switch (fileName) {
            case 'registry':
                this.file = this.registryFile;
                break;
            case 'fields':
                this.file = this.fieldsFile;
                break;
        }
        const formData: FormData = new FormData();
        formData.append('file', this.file, 'file');
        const headers = new Headers();
        headers.append('Content-Type', 'multipart/form-data');
        const options = {headers};

        try {
            this.loadingService.setLoading(true, this.loadingText);
            await this.fileService.uploadFile(url, formData, options);
            this.toastMessageService.toastMessages(`Η μεταφόρτωση ολοκληρώθηκε με επιτυχία`);
            location.reload();
        } catch (e) {
            this.toastMessageService.toastErrorMessage(e.error.message);
        } finally {
            this.loadingService.setLoading(false);
        }
    }

    async openDisabledDialog(field: string) {
        try {
            this.loadingService.setLoading(true);
            let result;
            if (field === 'is_disabled_crop') {
                result = await this.userService.fetchDisabledCropUsers();
            } else {
                result = await this.userService.fetchDisabledIrrigationUsers();
            }
            this.matDialog.open(DisabledConfigurationDialogComponent, {
                data: {
                  users: result,
                  field
                },
                width: '600px',
                disableClose: true
            });
        } catch (e) {
              this.toastMessageService.toastErrorMessage(e.message);
        } finally {
              this.loadingService.setLoading(false);
        }
   }

    async navigateToHelp() {
        await this.router.navigate(['start/help']);
    }
}
