import { Injectable } from '@angular/core';
import {CommonService} from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class AuditsService {

  constructor(private commonService: CommonService) { }

  async fetchAll(model: string) {
    return await this.commonService.getRequest(`statistics?model=${model}`);
  }
}
